import { FC, MouseEvent, ReactNode, useState } from "react"
import MenuList from "@mui/material/MenuList"
import Divider from "@mui/material/Divider"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import Typography from "@mui/material/Typography"
import CSS from 'csstype'
import LinkUI from "../LinkUI/LinkUI"
import { MenuUIDividerPropsI, MenuUIItemPropsI, MenuUIPropsI } from "./interfaces"

const optionsDefault = [
  { label: 'Select an Item' },
  { divider: true },
  { label: 'Item 1' },
  { label: 'Item 2' },
  { label: 'Item 3' }
]

const MenuUI: FC<MenuUIPropsI> = ({
  items = optionsDefault,
  sx,
  hoverItems,
  customFormatLink,
  list = true,
  ...props
}: MenuUIPropsI) => {
  const [hoverEl, setHoverEl] = useState<{[key: string]: boolean}>({})

  const formatIcon = (icon?: ReactNode | string): ReactNode|null =>
    icon ? <ListItemIcon data-testid="icon">{icon}</ListItemIcon> : null

  const formatLabel = (label?: ReactNode | string): ReactNode|null =>
    label ? <Typography component="span" data-testid="label">{label}</Typography> : null

  const defaultFormatLink = (e: MenuUIItemPropsI): ReactNode | null =>
    e.link ? <LinkUI href={e.link} linkText={formatLabel(e.label)} icon={formatIcon(e.icon)}/> :
      <>{formatIcon(e.icon)} {formatLabel(e.label)}</>

  const formatLink: (e: MenuUIItemPropsI) => ReactNode|null = customFormatLink ?? defaultFormatLink

  let sxHover: CSS.Properties = {}
  if (typeof hoverItems === 'string') {
    sxHover = {
      "backgroundColor": hoverItems
    }
  } else if (hoverItems) {
    sxHover = hoverItems
  }

  const hoverElem = (e: MouseEvent<HTMLElement>) => {
    const key = e.currentTarget.getAttribute('data-key') ?? ""
    setHoverEl({ [key]: !hoverEl[key] })
  }

  const myStyle = (k: number): CSS.Properties => hoverEl[k] ? sxHover : {}
  const buildItem = (menus: Array<MenuUIItemPropsI | MenuUIDividerPropsI>) =>
    menus && menus.map((e: MenuUIItemPropsI|MenuUIDividerPropsI, k) =>
      "divider" in e ?
        <Divider key={`divider-${k + 1}`} /> :
        <MenuItem
          key={`menu-${e?.idx ?? e.label}`}
          onClick={e.onClick}
          data-key={k}
          style={myStyle(k)}
          onMouseEnter={hoverElem}
          onMouseLeave={hoverElem}
          data-testid={`menu-${e?.idx ?? e.label}`}
          {...e.props}
        >
          {e.component ?
            <Typography component="span" data-testid="component">{e.component}</Typography> :
            formatLink(e)}
        </MenuItem>
    )

  const menuItem = buildItem(items)
  return list ? <MenuList sx={sx ?? { backgroundColor: 'white' }} {...props}>{menuItem}</MenuList> : <>{menuItem}</>
}

export default MenuUI
