import { FC, useContext } from "react"
import { useTranslation } from "next-i18next"
import useSWR from "swr"
import {
  IconAlertTriangle,
  IconBattery1,
  IconBattery2,
  IconBattery3,
  IconBattery4,
  IconClockHour4,
  IconPencil
} from "@tabler/icons-react"
import Tooltip from "@mui/material/Tooltip"
import { SubscriptionPropsI } from "@saas-repositories/allready/interfaces"
import { user_auth } from "@saas-prisma/auth"
import { BillingSubscriptionLabelPropsI } from "./interfaces"
import { useTheme } from "@mui/material/styles"
import { formatLocaleDate } from "@saas-utils/tools"
import ButtonUI from "@lib-ui/atoms/ButtonUI/ButtonUI"
import { openBillingDialog } from "@saas-reducers/Product"
import ReducerContext from "@saas-reducers/ReducerContext"

const BillingSubscriptionLabel: FC<BillingSubscriptionLabelPropsI> = ({
  showUser = true,
  showQuota = true,
  shortLabel = false,
  showUpdateButton = false,
}: BillingSubscriptionLabelPropsI) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation("stripe")
  const { productDispatch } = useContext(ReducerContext)
  const { data: subscriptions, isLoading: isLoadingSubscriptions } = useSWR<Array<SubscriptionPropsI>>('/api/user/subscriptions', {
    refreshInterval: 2000
  })
  const { data: usersList, isLoading: isLoadingUserList } = useSWR<Array<user_auth>>('/api/user/list')

  const totalUsers = usersList?.filter(item => item.is_enabled === 1).length ?? 0

  const buildTitle = (subscription: SubscriptionPropsI) => {
    let label = subscription.name
    if (shortLabel) {
      label = label.replace(/^(?:Monthly|Annual) - /i, '')
    }
    return subscription.status === "trialing" ? (
      <Tooltip arrow title={t('stripe:billingSubscription.trial-tooltip', {
        date: formatLocaleDate(i18n.language, subscription.trial_end as unknown as string)
      })}>
        <span className="inline-flex items-center">
          <IconClockHour4 color="white" fill={theme.palette.warning.main} stroke={1.25} />
          {label}
        </span>
      </Tooltip>
    ) : <span className="ml-1">{label}</span>
  }

  const buildLevel = (current: number, total: number) => {
    const step = total / 4

    const label = t(`stripe:billingSubscription.quota`, {
      count: total,
      current: current
    })

    if (current > step * 3) {
      return <span className="inline-flex items-center">
        <IconBattery4 color={theme.palette.success.main} stroke={1.5} /> {label}
      </span>
    }
    else if (current > step * 2) {
      return <span className="inline-flex items-center">
        <IconBattery3 color={theme.palette.success.main} stroke={1.5} /> {label}
      </span>
    }
    else if (current > step) {
      return <span className="inline-flex items-center">
        <IconBattery2 color={theme.palette.info.main} stroke={1.5} /> {label}
      </span>
    }
    else if (current > 0) {
      return <Tooltip title={t('stripe:billingSubscription.less20-quota-tooltip')}>
        <span className="text-warning inline-flex items-center">
          <IconBattery1 color={theme.palette.warning.main} />
          {label}
        </span>
      </Tooltip>
    }
    return <Tooltip title={t('stripe:billingSubscription.no-quota-tooltip')}>
      <span className="text-error inline-flex items-center">
        <IconAlertTriangle color="white" fill={theme.palette.error.main} />
        {label}
      </span>
    </Tooltip>
  }

  const buildContent = (subscription: SubscriptionPropsI) => {
    return <>
      {showQuota && !!subscription.current_quota && !!subscription.monthly_quota && (
        buildLevel(Number(subscription.current_quota), Number(subscription.monthly_quota))
      )}
      {showQuota && showUser && <br />}
      {showUser ? (
        <span className="ml-1">
          {t(`stripe:billingSubscription.user${isLoadingUserList ? '' : '_full'}`, {
            count: Number(subscription.available_user),
            user: totalUsers
          })}
        </span>
      ) : null}
    </>
  }

  const buildParamButton = () => {
    const isInternalTrial = !isLoadingSubscriptions ? !!subscriptions?.find(sub => sub.subscription_id === 'sub_trial') : false
    let param
    if (isInternalTrial || !subscriptions?.length) {
      param = {
        label: t('stripe:billingToken:subscribe-button'),
        onClick: () => handleSubscribe()
      }
    } else {
      param = {
        label: t('stripe:billingToken:update-subscribe-button'),
        href: "/api/subscription/customer_portal",
        target: "_blank"
      }
    }
    return param
  }

  const buildItem = (subscription: SubscriptionPropsI) => {
    return <div key={subscription.name} className="text-sm w-fit m-auto">
      <div className="py-4">
        <b>{buildTitle(subscription)}</b>
        <div className="text-grey">{buildContent(subscription)}</div>
      </div>
      {showUpdateButton && (
        <div className="text-left">
          <ButtonUI
            startIcon={<IconPencil stroke={1.25} />}
            variant="text"
            size="large"
            {...buildParamButton()}
          />
        </div>
      )}
    </div>
  }

  const handleSubscribe = () => {
    productDispatch(openBillingDialog({ filter: [], type: "plan" }))
  }

  return <>
    {subscriptions?.length ?
      <>{subscriptions.map(buildItem)}</> :
      <div className="my-4 py-4">
        <div className="text-center">
          <ButtonUI
            label={t('stripe:billingSubscription.no-subscription')}
            variant="text"
            size="large"
            onClick={() => handleSubscribe()}
          />
        </div>
      </div>}
  </>
}

export default BillingSubscriptionLabel
