import { FC, MouseEvent, useContext, useState } from "react"
import { signOut, useSession } from "next-auth/react"
import { MainHeaderPropsI } from "./interfaces"
import Grid from "@mui/material/Unstable_Grid2"
import styles from "./MainHeader.module.scss"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import { IconMenu2 } from "@tabler/icons-react"
import HeaderBaseLayout from "@saas-organisms/Layout/Header/Base/HeaderBaseLayout"
import MenuAccount from "@saas-molecules/Menu/Account/MenuAccount"
import Breadcrumbs from "@saas-molecules/Breadcrumbs/Breadcrumbs"
import MenuHelp from "@saas-molecules/Menu/Help/MenuHelp"
import { useUser } from "@saas-hooks"
import ReducerContext from "@saas-reducers/ReducerContext"

const MainHeader: FC<MainHeaderPropsI> = ({ drawerToggle, className, breadcrumbs }: MainHeaderPropsI) => {
  const { status } = useSession()
  const { user, isLoading } = useUser()
  const access = status === "authenticated"

  const [anchorElAccount, setAnchorElAccount] = useState<HTMLElement | null>(null)
  const [anchorElHelp, setAnchorElHelp] = useState<HTMLElement | null>(null)
  const { logout } = useContext(ReducerContext)

  const handleClickAccount = (e: MouseEvent<HTMLElement>) => {
    setAnchorElHelp(null)
    setAnchorElAccount(anchorElAccount ? null : e.currentTarget)
  }

  const handleClickHelp = (e: MouseEvent<HTMLElement>) => {
    setAnchorElAccount(null)
    setAnchorElHelp(anchorElHelp ? null : e.currentTarget)
  }

  const handlerSignOut = () => {
    setAnchorElAccount(null)
    signOut().catch(console.error)
    logout()
  }

  return <HeaderBaseLayout {...{
    className,
    middle: <Grid container justifyContent="space-between" alignItems="center" className={styles['container']}>
      <div className={styles['left']}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={drawerToggle}
          className={styles['drawerHeader']}
        >
          <IconMenu2 />
        </IconButton>
        {/*<MenuHeader />*/}
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <div className={styles['right']}>
        <Divider light={true} sx={ { margin: '.5em' } } orientation="vertical" flexItem />
        <MenuAccount
          user={!isLoading ? user : null}
          anchorEl={anchorElAccount}
          handleClick={handleClickAccount}
          handlerSignOut={handlerSignOut}
          light={!access}
        />
        <MenuHelp anchorEl={anchorElHelp} handleClick={handleClickHelp} />
      </div>
    </Grid>,
  }} />
}

export default MainHeader
