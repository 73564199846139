const GeneratorCode = (length = 5) => {
  const letters = 'abcdefghjklmnpqrstuvwxyz'
  let result = ''

  for (let i = 0; i < length; i++) {
    result += letters[Math.floor(Math.random() * letters.length)].toUpperCase() + Math.floor((Math.random() * 9) + 1)
  }
  return result
}

const generatePassword = (length: number, ditgit = true, lower = true, upper = true, special = false, similar = false): string => {
  const includeSpecial = '~!@#$%^&*()-_=+[]{};:,.<>/?€£µ`'
  const includeDigit = '0123456789'
  const includeLowercase = 'abcdefghijklmnopqrstuvwxyz'
  const includeUppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const excludeSimilar = /[1ilLI|`oO0]/g

  const charList = new Array<string>()
  if (ditgit) charList.push(includeDigit)
  if (lower) charList.push(includeLowercase)
  if (upper) charList.push(includeUppercase)
  if (special) charList.push(includeSpecial)

  let charConf = charList.join('')
  if (!similar) {
    charConf = charConf.replaceAll(excludeSimilar, '')
  }

  if (length < 1) {
    throw new Error('Length must be greater than zero.')
  } else if (!charConf.length) {
    throw new Error('Character list must contain at least one char.')
  }

  const result = new Array<string>()
  for (let i = 0; i < length; i++) {
    result.push(charConf[Math.floor(Math.random() * charConf.length)])
  }
  return result.join('')
}

export default GeneratorCode

export { generatePassword }

