import { FC } from "react"
import { FormTextFieldI } from "../interfaces"
import TextField from "@mui/material/TextField"
import styles from "../Field.module.scss"
import { useTranslation } from "next-i18next"
import { getIn } from "formik"

const TextareaUI: FC<FormTextFieldI> = ({
  label,
  defaultValueData,
  field,
  size,
  handleChange = () => null,
  form,
  error,
  helperText,
  classnamehasvalue,
  props
}: FormTextFieldI) => {
  const { t } = useTranslation(["translation", "pa", "com", "custom", "desc"])

  const errorMessage: string = getIn(form.errors, field.name) as string
  const setClassValue = (val: boolean) => val ? (classnamehasvalue ?? '') : ''
  const hasError = error ?? Boolean(errorMessage)
  const hasHelperText = helperText ?? (hasError ? t(errorMessage) : undefined)

  return <TextField
    className={styles['fieldProps']}
    helperText={hasHelperText}
    defaultValue={defaultValueData ?? field.value}
    name={field.name}
    onChange={(event) => handleChange(event.target.value)}
    onBlur={(event) => handleChange(event.target.value)}
    label={label}
    size={size}
    multiline
    minRows={props?.minRows ?? 4}
    maxRows={props?.maxRows ?? 8}
    {...props}
    InputProps={ {
      className: `${styles['textInputProps']} ${setClassValue(!!(defaultValueData ?? field.value))}`,
    } }
    InputLabelProps={ {
      className: styles['textPrimaryColorLabel'],
    } }
  />
}

export default TextareaUI
