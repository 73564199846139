import { FC, useState } from "react"
import { FormTextFieldI, NestedPropsI, SelectOptionPropsI } from "../interfaces"
import TextField from "@mui/material/TextField"
import styles from "./TextUI.module.scss"
import stylesField from "../Field.module.scss"
import InputAdornment from "@mui/material/InputAdornment"
import MenuItem from "@mui/material/MenuItem"
import Tooltip from "@mui/material/Tooltip"
import { getIn } from "formik"
import { useTranslation } from "next-i18next"
import { useTheme } from "@mui/material/styles"

const TextUI: FC<FormTextFieldI> = ({
  label,
  desc,
  defaultValueData,
  defaultValueSpecial,
  field,
  size,
  form,
  type,
  handleChange = field?.onChange,
  handleNestedChange = field?.onChange,
  error,
  classnamehasvalue,
  required,
  nested,
  props,
}: FormTextFieldI) => {
  const { t } = useTranslation(["translation", "pa", "com", "custom", "desc"])
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const errorMessage: string = getIn(form?.errors, field?.name) as string
  const handleClose = () => { setOpen(false) }
  const handleOpen = () => { setOpen(true) }

  const setClassValue = (val: boolean) => val ? (classnamehasvalue ?? '') : ''

  const hasError = error ?? Boolean(errorMessage)

  const helperText = hasError ? t(errorMessage) : (desc ? t(desc) : "")

  const buildMenuNested = (nested: NestedPropsI) => {
    const data: Array<SelectOptionPropsI> = nested.props.options ?? []
    return data.map((option) => (
      <MenuItem key={option.value} value={option.value} sx={ {
        backgroundColor: 'white',
        ":hover": { backgroundColor: "lightgrey" } } }
      >
        {t(option.label)}
      </MenuItem>
    ))
  }

  return <Tooltip describeChild title={label} arrow open={open} onClose={handleClose} onOpen={handleOpen}>
    <TextField
      className={stylesField['fieldProps']}
      variant="outlined"
      error={hasError}
      helperText={helperText}
      type={type}
      required={required}
      // defaultValue={defaultValueData ?? field.value}
      value={defaultValueData ?? field.value}
      name={field.name}
      onChange={(event) => handleChange(event.target.value)}
      onBlur={props?.onBlur !== false ? (event) => handleChange(event.target.value) : undefined}
      // @ts-ignore
      autoComplete={props?.autoComplete ?? ""}
      label={label}
      size={size}
      InputProps={ {
        autoComplete: 'nope',
        className: `${stylesField['textInputProps']} ${setClassValue(!!(defaultValueData ?? field.value))}`,
        endAdornment: nested && <InputAdornment position="end">
          <TextField variant="standard" name={nested.name} value={defaultValueSpecial} select
            onChange={(event) => handleNestedChange(event.target.value)}
            onBlur={props?.onBlur !== false ? (event) => handleNestedChange(event.target.value) : undefined}
            InputProps={ {
              disableUnderline: true
            } }
            defaultValue={defaultValueSpecial}
            SelectProps={ { IconComponent: () => null, className: stylesField['endAdornment'], } }
          >
            {buildMenuNested(nested)}
          </TextField>
        </InputAdornment>
      } }
      InputLabelProps={ {
        className: nested ? styles['textInputPropsNested'] : '',
        sx: {
          color: theme.palette.primary.main,
        }
      } }
    />
  </Tooltip>
}

export default TextUI
