import BillingSubscriptionLabel from "@saas-molecules/Billing/PackageLabel/BillingSubscriptionLabel"
import CardSimpleUI from "@lib-ui/molecules/CardUI/CardSimpleUI/CardSimpleUI"
import useSWR from "swr"
import { SubscriptionPropsI } from "@saas-repositories/allready/interfaces"
import { Trans, useTranslation } from "next-i18next"
import Link from "next/link"
import { openBillingDialog } from "@saas-reducers/Product"
import { useContext } from "react"
import ReducerContext from "@saas-reducers/ReducerContext"
import { batch } from "@saas-reducers/Contact"
import { useUser } from "@saas-hooks"

const BillingUpsell = () => {
  const { t } = useTranslation("stripe")
  const { productDispatch, contactDispatch } = useContext(ReducerContext)

  const { user, isLoading } = useUser()
  const { data: subscriptions, isLoading: isLoadingSubscriptions } = useSWR<Array<SubscriptionPropsI>>('/api/user/subscriptions', {
    refreshInterval: 2000
  })

  const noQuota = !!subscriptions?.find(a => a.monthly_quota === null)

  const totalCurrent = (subscriptions ?? []).map(a => Number(a.current_quota)).reduce((a, b) => a + b, 0)
  const totalQuota = (subscriptions ?? []).map(a => Number(a.monthly_quota)).reduce((a, b) => a + b, 0)

  const resPercent = totalCurrent * 100 / totalQuota

  const handleSubscribe = () => {
    productDispatch(openBillingDialog({ filter: [], type: "plan" }))
  }

  const isInternalTrial = !isLoadingSubscriptions ? !!subscriptions?.find(sub => sub.subscription_id === 'sub_trial') : false
  let linkPlan = <Link href="/api/subscription/customer_portal" target="_blank" />
  if (isInternalTrial) {
    linkPlan = <a href="#" onClick={() => handleSubscribe()} />
  }

  const handleContact = () => {
    contactDispatch(batch({
      reportType: 'plan',
      open: true,
      extra: []
    }))
  }
  const linkContact = <a href="#" onClick={() => handleContact()} />

  const id = process.env.NEXT_PUBLIC_ROLES_MANAGE_ID ?? ""
  const canManage = !isLoading && (user?.roles ?? []).includes(id)

  return <CardSimpleUI title={""} className="!p-0">
    <BillingSubscriptionLabel showUser={false} shortLabel />
    {!noQuota && resPercent < 50 && <p className="text-sm text-left px-4 pb-4">
      {canManage ? (
        <Trans
          i18nKey="stripe:billingSubscription.upsell_msg"
          components={ {
            br: <br />,
            link1: linkPlan,
            link2: linkContact,
          } }
        />
      ) : t("stripe:billingSubscription.upsell_msg_sub_user")}
    </p>}
  </CardSimpleUI>
}

export default BillingUpsell
