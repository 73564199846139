import { Trans, useTranslation } from "next-i18next"
import { IconTicket } from "@tabler/icons-react"
import type { user_token } from "@saas-prisma/allready"
import ButtonUI from "@lib-ui/atoms/ButtonUI/ButtonUI"
import { FC, useContext } from "react"
import { BillingTokenLabelPropsI } from "@saas-molecules/Billing/TokenLabel/interfaces"
import { openBillingDialog } from "@saas-reducers/Product"
import ReducerContext from "@saas-reducers/ReducerContext"
import useSWR from "swr"
import { useUser } from "@saas-hooks"

const BillingTokenLabel: FC<BillingTokenLabelPropsI> = ({
  useLabel = false
}) => {
  const { t, i18n } = useTranslation("stripe")
  const { productDispatch } = useContext(ReducerContext)

  const { data: token, isLoading } = useSWR<user_token[]>('/api/user/tokens', {
    refreshInterval: 2000
  })

  const { user, isLoading: isUserLoading } = useUser()
  const canManage = () => !isUserLoading && user ? ((user?.roles ?? []).includes(process.env.NEXT_PUBLIC_ROLES_MANAGE_ID ?? "")) : false

  const getTotal = (tokens: user_token[]) => (tokens ?? [])
    .map(t => Number(t.available_token))
    .reduce((t1, t2) => t1 + t2, 0)

  const formatNumber = (num: number) =>
    new Intl.NumberFormat(i18n.language, { minimumFractionDigits: 0 }).format(num)

  const handleTokens = () => {
    productDispatch(openBillingDialog({ filter: [], type: "pack" }))
  }

  const num = !isLoading && token && token?.length ? getTotal(token) : 0
  const formattedNum = formatNumber(num)

  return <>
    {(!isLoading && token && token?.length) ? (
      <div className="text-grey text-sm w-fit m-auto">
        {useLabel ? (
          <>
            <Trans
              i18nKey={`stripe:billingToken.label`}
              values={ { count: num, formattedNum: formattedNum } }
              components={ { b: <b className="text-dark" />, span: <span className="font-lato" /> } }
            />
          </>
        ) : (
          <div className="px-4 py-2 rounded-full flex items-center justify-center bg-primary text-white shadow-lg text-lg font-lato">
            {formattedNum}
          </div>
        )}
      </div>
    ) : (
      <p className="my-4">{t("stripe:billingToken.no-token")}</p>
    )}
    {canManage() &&
      <ButtonUI
        startIcon={<IconTicket stroke={1.25} />}
        label={t('stripe:billingToken:buy-button')}
        variant="text"
        size="large"
        onClick={() => handleTokens()}
      />}
  </>
}

export default BillingTokenLabel
