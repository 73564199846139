import { FC, SyntheticEvent, useEffect, useState } from "react"
import { AutocompleteUIPropsI } from "./interfaces"
import { FormTextFieldI, SelectOptionPropsI } from "../interfaces"
import TextField from "@mui/material/TextField"
import { useTranslation } from "next-i18next"
import { getIn } from "formik"
import Autocomplete from '@mui/material/Autocomplete'
import styles from "../Field.module.scss"
import Checkbox from "@mui/material/Checkbox"
import { CheckTertiary, NotCheckedTertiary } from "@lib-ui/themes"
import type { AutocompleteCloseReason } from "@mui/base/useAutocomplete/useAutocomplete"
import Popper, { PopperProps } from "@mui/material/Popper"

const PopperMy = (props: PopperProps) => <Popper {...props} style={ { width: "max-content", minWidth: "15em" } } placement='bottom-start' />

const AutocompleteUI: FC<FormTextFieldI> = ({
  label,
  desc,
  defaultValueData,
  field,
  size,
  form,
  handleChange = () => null,
  error,
  classnamehasvalue,
  maxSelection,
  required,
  props
}: FormTextFieldI & AutocompleteUIPropsI) => {
  const { t } = useTranslation(["translation", "pa", "com", "custom", "desc"])
  const [open, setOpen] = useState<boolean>(false)

  const [inputValue, setInputValue] = useState<string>('')
  const [value, setValue] = useState<SelectOptionPropsI[] | null>(null)
  const [options, setOptions] = useState<readonly SelectOptionPropsI[]>([])

  const errorMessage: string = getIn(form.errors, field.name) as string
  const setClassValue = (val: boolean) => val ? (classnamehasvalue ?? '') : ''
  const hasError = error ?? Boolean(errorMessage)
  const errorText = hasError ? t(errorMessage) : ""

  const isMulti = maxSelection === undefined || Number(maxSelection) > 1
  const disableClearable = props?.clear === false
  const isFreeSolo = true
  const maxItemsReached = isMulti && maxSelection && (value?.length ?? 0) >= Number(maxSelection)
  const buildHelperText = () => {
    if (errorText !== "") {
      return errorText
    } else if (maxItemsReached) {
      return t('field.autocompleteUI.max-option')
    } else if (isMulti && maxSelection !== undefined) {
      return t('field.autocompleteUI.limit-option', { limit: maxSelection })
    }
    return desc ? t(desc) : ""
  }

  const onChange = (valueChange: string | SelectOptionPropsI | (string | SelectOptionPropsI)[] | null) => {
    let result: SelectOptionPropsI[] = []
    if (valueChange) {
      if (!Array.isArray(valueChange)) {
        result = [valueChange as SelectOptionPropsI]
      }
      else {
        result = valueChange as SelectOptionPropsI[]
      }
    }
    setValue(result)
    setInputValue("")
    const res = result.map((item: SelectOptionPropsI) => item.value)
    handleChange(res)
  }

  useEffect(() => {
    const initialValue = (defaultValueData ?? field.value ?? []) as string[] | string
    let defaultVal: string[]
    if (!Array.isArray(initialValue)) {
      defaultVal = [initialValue]
    } else {
      defaultVal = initialValue
    }

    const data: Array<SelectOptionPropsI> = props?.options ?? []
    data.forEach(option => option.label = t(option.label))
    if (props?.sort === true) {
      data.sort((n1, n2) => n1.label.localeCompare(n2.label))
    }
    setOptions(data)

    const dataVal = data.filter(opt => defaultVal.includes(opt.value))

    if (initialValue.length) {
      onChange(dataVal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.options])

  const checkValueOnClose = (_evt: SyntheticEvent, reason: AutocompleteCloseReason) => {
    if (inputValue !== '' && reason === 'blur') {
      const valRes = value?.map(arr => arr.value) ?? []
      const res = options.filter(opt =>
        opt.label === inputValue || (isMulti && valRes.includes(opt.value))
      )
      onChange(res)
    }
    if (reason !== 'toggleInput') {
      setOpen(false)
    }
  }

  return (
    <Autocomplete<SelectOptionPropsI, typeof isMulti, typeof disableClearable, typeof isFreeSolo>
      options={options}
      className={styles['fieldProps']}
      limitTags={1}
      multiple={isMulti}
      open={open}
      onOpen={() => { setOpen(true) }}
      onClose={checkValueOnClose}
      // PopperComponent={StyledPopper}
      disableCloseOnSelect={isMulti}
      freeSolo={isFreeSolo}
      size={size}
      disableClearable={props?.clear === false}
      onChange={(e, value: string | SelectOptionPropsI | (string | SelectOptionPropsI)[] | null) => onChange(value)}
      value={value ?? (isMulti ? [] : null)}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      getOptionDisabled={(option) =>
        !!(maxItemsReached && !(value ?? []).includes(option))
      }
      PopperComponent={PopperMy}
      getOptionLabel={(option: string | SelectOptionPropsI | SelectOptionPropsI[]) => {
        if (typeof option === "string") {
          return option
        } else if (Array.isArray(option)) {
          return option.map(opt => opt.label).join(" ")
        }
        return option.label
      }}
      renderOption={(props, option, { selected }) =>
        <li {...{ ...props, key: option.value }}>
          {isMulti && <Checkbox
            icon={<NotCheckedTertiary width={20} height={20} />}
            checkedIcon={<CheckTertiary width={20} height={20} />}
            checked={selected}
          />}
          <span className="whitespace-nowrap">{option.label}</span>
          {/*{option.label}*/}
        </li>
      }
      isOptionEqualToValue={(option, value) => {
        if (Array.isArray(value)) {
          if (value.length) {
            value = value[0]
          } else {
            return false
          }
        }
        return option.value === value?.value
      }}
      renderInput={(params) => {
        return <TextField
          {...params}
          label={`${label}${required ? ' *' : ''}`}
          error={hasError}
          name={field.name}
          helperText={buildHelperText()}
          InputProps={ {
            ...params.InputProps,
            className: `${params.InputProps?.className} ${styles['textInputProps']} ${setClassValue((value?.length ?? 0) > 0)}`,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
              </>
            ),
          } }
          InputLabelProps={ {
            className: `${params.InputLabelProps?.className} ${styles['textPrimaryColorLabel']}`,
          } }
        />
      }}
      noOptionsText={t('field.autocompleteUI.no-option')}
    />
  )
}

export default AutocompleteUI
