const YOUR_API_KEY = process.env.NEXT_PUBLIC_HERE_COM_KEY

const buildUriApi = (apiName: string) =>
  `https://${apiName}.search.hereapi.com/v1/${apiName}?apiKey=${YOUR_API_KEY}`

const fetchApi = (apiName: string, params: string) =>
  fetch(`${buildUriApi(apiName)}&${params}`)
    .then(res => res.json())
    .catch(console.error)

export const fetchAutocompleteAPI = (value: string, locale = 'en-US', country: Array<string> = []) => {
  const params: Array<string> = [`lang=${locale}`, `q=${value}`]
  if (country.length) {
    params.push(`in=countryCode:${country.join(',')}`)
  }

  return fetchApi('autocomplete', params.join('&'))
}

export const fetchGeocodeAPI = (value: string, locale = 'en-US', country: Array<string> = []) => {
  const params: Array<string> = [`lang=${locale}`, `qq=${value}`]
  if (country.length) {
    params.push(`in=countryCode:${country.join(',')}`)
  }
  return fetchApi('geocode', params.join('&'))
}

export const fetchRevGeocodeAPI = (lat: number, lng: number, locale = 'en-US') =>
  fetchApi('revgeocode', `lang=${locale}&at=${lat},${lng}`)

export const fetchLookupAPI = (id: string, locale = 'en-US') =>
  fetchApi('lookup', `lang=${locale}&id=${id}`)
