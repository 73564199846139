import { FC } from "react"
import Drawer from "@mui/material/Drawer"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from '@mui/material/styles'
import Typography from "@mui/material/Typography"
import { SideBarLayoutPropsI } from "./interfaces"
import styles from './SideBarLayout.module.scss'
import Divider from "@mui/material/Divider"
import Logo from "@saas-atoms/Logo/Logo"
import MenuFooter from "@saas-molecules/Menu/Footer/MenuFooter"
import BillingUpsell from "@saas-organisms/Billing/Upsell/BillingUpsell"
import BillingToken from "@saas-organisms/Billing/Token/BillingToken"

const SideBarLayout: FC<SideBarLayoutPropsI> = ({ drawerOpen, drawerToggle, footerSidebar, children }: SideBarLayoutPropsI) => {
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('lg'))

  const divider = <Divider light={true} sx={ {
    margin: '0 2em 1em'
  } } />

  return <Drawer
    variant={matchUpMd ? 'persistent' : 'temporary'}
    anchor="left"
    open={drawerOpen}
    onClose={drawerToggle}
    ModalProps={ { keepMounted: true } }
    color="inherit"
    className={styles['container']}
    PaperProps={ {
      className: styles['MuiDrawer-paper'],
    } }
  >
    <div className={styles['content']}>
      <Typography noWrap component="div" sx={ { display: { lg: 'none' }, textAlign: "center", m: 0 } }>
        <Logo marginTop="1.5em" marginLeft="0" />
      </Typography>
      {divider}
      {children ? children : <div>Sidebar</div>}
    </div>
    {footerSidebar && <div className={`${styles['content']} ${styles['footer']}`}>
      <BillingToken />
      <BillingUpsell />
      {divider}
      <MenuFooter />
    </div>}
  </Drawer>
}

export default SideBarLayout
