import { FC, useContext } from "react"
import Grid from "@mui/material/Unstable_Grid2"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from '@mui/material/styles'
import { MainLayoutPropsI } from "./interfaces"
import styles from './MainLayout.module.scss'
import HeadBaseLayout from "@saas-organisms/Layout/Head/Base/HeadBaseLayout"
import MainHeader from "@saas-organisms/Header/Main/MainHeader"
import SideBarLayout from "@saas-organisms/Layout/Sidebar/SideBarLayout"
import ReducerContext from "@saas-reducers/ReducerContext"
import { setCustomizeMenu } from "@saas-reducers/Customize"

const MainLayout: FC<MainLayoutPropsI> = ({
  head,
  breadcrumbs,
  components,
  className,
  footerSidebar = true,
  children
}: MainLayoutPropsI) => {

  const { customizeState: state, customizeDispatch: dispatch } = useContext(ReducerContext)
  let leftDrawerOpened = state?.menu ?? false

  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('lg'))

  const handleLeftDrawerToggle = () => {
    dispatch(setCustomizeMenu({ menu: !leftDrawerOpened }))
  }
  leftDrawerOpened = matchUpMd ? true : leftDrawerOpened

  if (components?.leftSide) {
    className ??= {}
    className.article = `${className?.article ?? ''} ${styles['left-side-base']}`
  }

  return <>
    {head ?? <HeadBaseLayout />}
    {components?.header ?? <MainHeader drawerToggle={handleLeftDrawerToggle} className={className?.header} breadcrumbs={breadcrumbs} />}
    <Grid container component="article" className={`${styles['article']} ${className?.article ? className?.article : ''}`}>
      {components?.leftSide ? (
        <Grid component="aside" className={`${className?.left ? className?.left : ''} ${styles['left-side']}`}>
          <SideBarLayout drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} footerSidebar={footerSidebar}>{components?.leftSide}</SideBarLayout>
        </Grid>
      ) : null}
      <Grid component="section" className={`${styles["container"]} ${className?.section ? className?.section : ''}`}>
        <div className={`${styles["content"]}`}>
          {children}
        </div>
      </Grid>
      {components?.rightSide ? (
        <Grid component="aside" className={`${className?.right ? className?.right : ''}`}>{components?.rightSide}</Grid>
      ) : null}
    </Grid>
    {components?.footer}
  </>
}

export default MainLayout
