import { FC, SyntheticEvent, useEffect, useState } from "react"
import { FormTextFieldI } from "../interfaces"
import parse from 'autosuggest-highlight/parse'
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { IconMapPin } from "@tabler/icons-react"
import { useTranslation } from "next-i18next"
import { fetchAutocompleteAPI, fetchGeocodeAPI, fetchLookupAPI, fetchRevGeocodeAPI } from "@saas-services/HereComServices"
import { HereAutocompletionResultTypePropI, HereLookupResultTypePropI, HereResultTypePropI } from "@saas-atoms/Field/GeocodingUI/interfaces"
import styles from "@saas-atoms/Field/Field.module.scss"
import { getIn } from "formik"
import Popper, { PopperProps } from "@mui/material/Popper"
import { useDebounce } from "@saas-hooks"
import type { AutocompleteCloseReason } from "@mui/base/useAutocomplete/useAutocomplete"

const GeocodingUI: FC<FormTextFieldI> = ({
  label,
  desc,
  defaultValueData,
  handleChange = () => null,
  field,
  size,
  form,
  error,
  classnamehasvalue,
  required,
  props
}: FormTextFieldI) => {
  const { t, i18n } = useTranslation(["translation", "pa", "com", "custom", "desc"])

  const [open, setOpen] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [active, setActive] = useState<boolean>(false)
  const [pick, setPick] = useState<string|null>(null)
  const [value, setValue] = useState<HereResultTypePropI | null>(null)
  const [options, setOptions] = useState<readonly HereResultTypePropI[]>([])

  const errorMessage: string = getIn(form.errors, field.name) as string
  const setClassValue = (val: boolean) => val ? (classnamehasvalue ?? '') : ''
  const hasError = error ?? Boolean(errorMessage)
  const errorText = hasError ? t(errorMessage) : ""
  const buildHelperText = () => {
    if (errorText !== "") {
      return errorText
    }
    return desc ? t(desc) : ""
  }

  const fetchAutocomplete = useDebounce(
    (value: string, callback: (results: HereAutocompletionResultTypePropI) => void) => {
      fetchAutocompleteAPI(value, i18n.language, props?.country ?? []).then(callback)
    }
  )

  const fetchGeocode = useDebounce(
    (value: string, callback: (results: HereAutocompletionResultTypePropI) => void, callbackError: () => void) => {
      fetchGeocodeAPI(value, i18n.language, props?.country ?? []).then(callback).catch(callbackError)
    }
  )

  const fetchLookup = useDebounce(
    (value: string, callback: (result: HereLookupResultTypePropI) => void, callbackError: () => void) => {
      fetchLookupAPI(value, i18n.language).then(callback).catch(callbackError)
    },
    0)

  const fetchRevGeocode = useDebounce(
    (lat: number, lng: number, callback: (result: HereLookupResultTypePropI) => void) => {
      fetchRevGeocodeAPI(lat, lng, i18n.language).then(callback)
    }
  )

  const buildSearchGeocode = (defaultValueData: Record<string, string|undefined>|string) => {
    const val = defaultValueData as Record<string, string|undefined>
    const search = []
    if (typeof defaultValueData === "string") {
      search.push(val)
    } else {
      val?.district && search.push(`district=${val?.district}`)
      val?.houseNumber && search.push(`houseNumber=${val.houseNumber}`)
      val?.street && search.push(`street=${val.street}`)
      val?.postalCode && search.push(`postalCode=${val.postalCode}`)
      val?.city && search.push(`city=${val.city}`)
      val?.county && search.push(`county=${val?.county}`)
      val?.state && search.push(`state=${val?.state}`)
      val?.countryName && search.push(`country=${val?.countryName}`)
    }

    return search.join(";")
  }

  useEffect(() => {
    setActive(false)

    const searchGeocode = (valGeo: Record<string, string|undefined>, canPick: boolean = true) => {
      const searchVal = buildSearchGeocode(valGeo)
      if (searchVal.length) {
        fetchGeocode(searchVal, (datas: HereAutocompletionResultTypePropI) => {
          const results = datas.items
          const data = results[0] ?? []

          canPick && setPick(data.id)
          setValue(data)
          setOptions([data])
          setInputValue(data?.address?.label ?? "")
        })
      }
    }

    if (inputValue === '' && defaultValueData) {
      const val = defaultValueData as Record<string, string|undefined>
      if (val?.id?.length) {
        fetchLookup(val.id, (data: HereResultTypePropI) => {
          if (data.hasOwnProperty("error")) {
            searchGeocode(defaultValueData as Record<string, string | undefined>, false)
          } else {
            setPick(null)
            setValue(data)
            setOptions([data])
            setInputValue(data?.address?.label ?? "")
          }
        }, () => {
          searchGeocode(defaultValueData as Record<string, string | undefined>)
        })
      } else if (val?.lat && val?.lng) {
        fetchRevGeocode(val.lat, val.lng, (datas: HereAutocompletionResultTypePropI) => {
          const results = datas.items
          const data = results[0] ?? []

          setPick(data.id)
          setValue(data)
          setOptions([data])
          setInputValue(data?.address?.label ?? "")
        })
      } else {
        searchGeocode(defaultValueData as Record<string, string|undefined>)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (active) {
      if (inputValue === '') {
        options.length && setOptions([])
        value && setValue(null)
        handleChange(null)
        return undefined
      }

      if (pick) {
        fetchLookup(pick, (data: HereLookupResultTypePropI) => {
          setPick(null)
          const saveData = {
            id: pick,
            lat: data.position.lat,
            lng: data.position.lng,
            ...value?.address ?? {}
          }
          handleChange(saveData)
        }, () => {
          const searchVal = buildSearchGeocode(value?.address as Record<string, string|undefined>)
          if (searchVal.length) {
            fetchGeocode(searchVal, (datas: HereAutocompletionResultTypePropI) => {
              const results = datas.items
              let selectLatLong = false
              results.forEach(item => {
                if (item.id === pick) {
                  if (item?.position) {
                    selectLatLong = true
                    const saveData = {
                      id: pick,
                      lat: item.position.lat,
                      lng: item.position.lng,
                      ...value?.address ?? {}
                    }
                    handleChange(saveData)
                  }
                }
              })
              if (!selectLatLong) {
                const saveData = {
                  ...value?.address ?? {}
                }
                handleChange(saveData)
              }
            }, () => {
              const saveData = {
                ...value?.address ?? {}
              }
              handleChange(saveData)
            })
          }
        })
      } else {
        fetchAutocomplete(inputValue, (datas: HereAutocompletionResultTypePropI) => {
          const results = datas.items
          let newOptions: HereResultTypePropI[] = []

          if (value) {
            newOptions = [value]
          }

          const allLabel = newOptions.map(item => item.address.label)
          results.forEach((item) => {
            if (item.resultType !== 'intersection' && !allLabel.includes(item.address.label)) {
              newOptions.push(item)
            }
          })

          setOptions(newOptions
            .sort((a, b) => b.resultType.localeCompare(a.resultType))
            .sort((a, b) => {
              if (b?.localityType && a?.localityType) {
                return b.localityType.localeCompare(a.localityType)
              }
              return 0
            })
            .sort((a, b) => {
              if (b?.administrativeAreaType && a?.administrativeAreaType) {
                return b.administrativeAreaType.localeCompare(a.administrativeAreaType)
              }
              return 0
            }))
        })
      }
    } else {
      setActive(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  const checkValueOnClose = (_evt: SyntheticEvent, reason: AutocompleteCloseReason) => {
    if (inputValue !== '' && reason === 'blur') {
      const res = options.find(opt =>
        opt.address.label === inputValue
      )
      // onChange(res)
      setValue(res ?? null)
      setPick((res as HereResultTypePropI)?.id ?? null)
      if (!res) {
        setInputValue("")
      }
    }
    if (reason !== 'toggleInput') {
      setOpen(false)
    }
  }

  const MyPopper = (props: PopperProps) => <Popper {...props} style={ { width: "max-content", minWidth: "15em" } } placement='bottom-start' />
  return (
    <Autocomplete<HereResultTypePropI, boolean | undefined, boolean | undefined, boolean | undefined>
      getOptionLabel={(option: string | HereResultTypePropI) => (typeof option === "string" ? option : (option?.address?.label ?? option?.title))}
      filterOptions={(x) => x}
      className={styles['fieldProps']}
      groupBy={(option) => {
        let label: string = option.resultType as string
        if (option.resultType === 'locality') {
          label = option.localityType as string
        }
        else if (option.resultType === 'administrativeArea') {
          label = option.administrativeAreaType as string
        }
        return t(`field.geocodingUI.option-grp.${label}`)
      }}
      open={open}
      onOpen={() => { setOpen(true) }}
      onClose={checkValueOnClose}
      options={options}
      size={size}
      noOptionsText={t('field.autocompleteUI.no-option')}
      value={value}
      onChange={(_evt, newValue) => {
        setValue(newValue as HereResultTypePropI)
        setPick((newValue as HereResultTypePropI)?.id ?? null)
      }}
      freeSolo
      inputValue={inputValue}
      onInputChange={(_evt, newInputValue) => setInputValue(newInputValue)}
      PopperComponent={MyPopper}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          error={hasError}
          name={field.name}
          helperText={buildHelperText()}
          InputProps={ {
            ...params.InputProps,
            className: `${params.InputProps?.className} ${styles['textInputProps']} ${setClassValue(!!inputValue?.length)}`,
          } }
          InputLabelProps={ {
            className: `${params.InputLabelProps?.className} ${styles['textPrimaryColorLabel']}`,
          } }
        />
      )}
      renderOption={(props, option) => {
        const matches = option.highlights?.address?.label || []

        const parts = parse(
          option.address?.label ?? option.title,
          matches.map(match => [match.start, match.end]),
        )

        return (
          <li {...props}>
            <div className="flex items-center">
              <div className="flex-1 mr-2">
                <IconMapPin stroke={1.25} />
              </div>
              <div className="grow text-left">
                {parts.map((part, index) => (
                  <span key={index} className={part.highlight ? 'font-bold' : 'font-normal'}>
                    {part.text}
                  </span>
                ))}
              </div>
            </div>
          </li>
        )
      }}
    />
  )
}

export default GeocodingUI
