import CardSimpleUI from "@lib-ui/molecules/CardUI/CardSimpleUI/CardSimpleUI"
import BillingTokenLabel from "@saas-molecules/Billing/TokenLabel/BillingTokenLabel"

const BillingToken = () => {
  return <CardSimpleUI title={""} className="!my-4 !px-0">
    <BillingTokenLabel useLabel />
  </CardSimpleUI>
}

export default BillingToken
