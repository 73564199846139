import { ReactNode } from "react"
import { useTranslation } from 'next-i18next'
import { getIn } from 'formik'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Checkbox from '@mui/material/Checkbox'
import Checked from '@saas-icons/checkbox-checked.ico.svg'
import NotChecked from '@saas-icons/checkbox.ico.svg'
import NotCheckedError from '@saas-icons/checkbox-error.ico.svg'
import Password from './Password'
import Calc from './Calc'
import DpeGesUI from './DpeGesUI'
import DateUI from "@saas-atoms/Field/DateUI/DateUI"
import MonthUI from "@saas-atoms/Field/MonthUI/MonthUI"
import YearUI from "@saas-atoms/Field/YearUI/YearUI"
import TextareaUI from "@saas-atoms/Field/TextareaUI/TextareaUI"
import AutocompleteUI from "@saas-atoms/Field/AutocompleteUI/AutocompleteUI"
import TextUI from "@saas-atoms/Field/TextUI/TextUI"
import NumberUI from "@saas-atoms/Field/NumberUI/NumberUI"
import TimeUI from "@saas-atoms/Field/TimeUI/TimeUI"
import RatingUI from "@saas-atoms/Field/RatingUI/RatingUI"
import RangeUI from "@saas-atoms/Field/RangeUI/RangeUI"
import styles from './FormTextField.module.scss'
import { FormTextFieldI, ValuePropsI } from "@saas-atoms/Field/interfaces"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import LibImagesUI from "@saas-atoms/Field/LibImagesUI/LibImagesUI"
import GeocodingUI from "@saas-atoms/Field/GeocodingUI/GeocodingUI"
import S3UploadUI from "@saas-atoms/Field/S3UploadUI/S3UploadUI"

/**
 * Material TextField Component with Formik Support including Errors.
 * Intended to be specified via the `component` prop in a Formik <Field> or <FastField> component.
 * Material-UI specific props are passed through.
 */
const FormTextField = (props: FormTextFieldI) => {
  const { t } = useTranslation(["translation", "pa", "com", "custom", "desc"])

  const {
    error,
    helperText,
    field,
    calc,
    form,
    type,
    handleDisplayArrow,
    handleChange = () => null,
    display,
    defaultValueData,
    label,
    size,
    ...rest
  } = props

  const errorMessage: string = getIn(form.errors, field.name) as string

  const setClassValue = (val: boolean) => val ? (rest.classnamehasvalue ?? '') : ''

  const hasError = error ?? Boolean(errorMessage)
  const hasHelperText = helperText ?? (hasError ? t(errorMessage) : undefined)

  const TextFieldBase: {[key: string]: ReactNode} = {
    'newLine': Array.from(Array(rest.props?.grow ?? 1).keys()).map(item => <div key={item} className="md:basis-1/4"></div>),
    'textarea': <div className="basis-full md:basis-2/3 xl:basis-2/4"><TextareaUI {...props} /></div>,
    'autocomplete': <div className="basis-full md:basis-1/4"><AutocompleteUI {...props} /></div>,
    'geocoding': <div className="basis-full md:basis-1/4"><GeocodingUI {...props} /></div>,
    'select': <div className="basis-full md:basis-1/4"><AutocompleteUI {...props} maxSelection={1} /></div>,
    'selectMulti': <div className="basis-full md:basis-1/4"><AutocompleteUI {...props} /></div>,
    'date': <div className="basis-full md:basis-1/4"><DateUI {...props} /></div>,
    'year': <div className="basis-full md:basis-1/4"><YearUI {...props} /></div>,
    'month': <div className="basis-full md:basis-1/4"><MonthUI {...props} /></div>,
    'time': <div className="basis-full md:basis-1/4"><TimeUI {...props} /></div>,
    'rating': <div className="basis-full md:basis-1/4"><RatingUI {...props} /></div>,
    'number': <div className="basis-full md:basis-1/4"><NumberUI {...props} /></div>,
    'integer': <div className="basis-full md:basis-1/4"><NumberUI {...props} /></div>,
    'text': <div className="basis-full md:basis-1/4"><TextUI {...props} /></div>,
    'range': <div className="basis-full md:basis-1/4"><RangeUI {...props} /></div>,
    'upload': <div className="basis-full md:basis-1/4"><S3UploadUI {...props} /></div>,
    'libImages': <LibImagesUI {...props} />,
    'hidden': <input
      type="hidden"
      value={defaultValueData as string ?? field.value}
      name={field.name}
    />,
    // DPE / GES
    'DpeGes': <div className="basis-full md:basis-1/2">
      <DpeGesUI {...{
        handleDisplayArrow,
        field,
        form,
        display: { dpe: display?.dpe, ges: display?.ges },
        defaultValueData: defaultValueData ?? field.value as ValuePropsI,
        props: props?.props
      }} />
    </div>,
    'switch': <FormControlLabel
      labelPlacement="start"
      defaultChecked={Boolean(defaultValueData ?? field.value)}
      control={
        <Switch
          size={size}
          checked={Boolean(defaultValueData ?? field.value)}
          onChange={(event, checked) => handleChange(checked)}
          name={field.name}
        />}
      label={label}
    />,
    'checkbox': <div className="basis-full md:basis-1/4">
      <FormControl error={hasError} className={styles['checkbox-container']}>
        <FormControlLabel
          className={`${(rest?.props as {className?: string})?.className ?? styles['checkbox-content']} ${setClassValue(!!(defaultValueData ?? field.value))}`}
          control={
            <Checkbox
              className={styles['checkbox']}
              checked={Boolean(defaultValueData ?? field.value)}
              onChange={(event, checked) => handleChange(checked)}
              size={size}
              name={field.name}
              icon={hasError ? <NotCheckedError width="1.7em" height="1.7em" /> : <NotChecked width="1.7em" height="1.7em" />}
              checkedIcon={<Checked width="1.7em" height="1.7em" />}
            />
          }
          label={`${label}${rest.required ? ' *' : ''}`}
        />
        <FormHelperText>{hasHelperText}</FormHelperText>
      </FormControl>
    </div>,
    'password': <div className="basis-full md:basis-1/4">
      <Password {
        ...{
          field,
          onChange: (event) => handleChange(event.target.value),
          onBlur: (event) => handleChange(event.target.value),
          rest,
          label,
          error: hasError,
          helperText: hasHelperText,
          size
        }
      } />
    </div>,
    'auto': <div className="basis-full md:basis-1/4">
      <Calc {...{
        onChange: (event) => handleChange(event.target.value),
        calc,
        field,
        label,
        defaultValueData: defaultValueData,
        error: hasError,
        helperText: hasHelperText,
        ...rest
      }} />
    </div>,
  }

  return TextFieldBase[type] ?? TextFieldBase[`text`]
}

export default FormTextField