import type { TFunction } from "next-i18next"
import { Theme } from "@mui/material/styles"
import { IconMessageCircle2, IconMessageReport, IconHelp } from "@tabler/icons-react"
import { MouseEvent } from "react"

export const menuList = (t: TFunction, theme: Theme, handleClickContact: (e: MouseEvent<HTMLElement>) => void) => [
  {
    label: `${t('menu.help.tuto')}`,
    link: '#tuto',
    icon: <IconMessageCircle2 color={theme.palette.primary.main} stroke={1.25} />,
    props: {
      disabled: true
    }
  },
  {
    label: `${t('menu.help.faq')}`,
    link: '#faq',
    icon: <IconHelp color={theme.palette.primary.main} stroke={1.25} />,
    props: {
      disabled: true
    }
  },
  {
    label: `${t('menu.help.bug')}`,
    // link: '#contact',
    onClick: handleClickContact,
    icon: <IconMessageReport color={theme.palette.primary.main} stroke={1.25} />
  }
]