import { ChangeEvent, FC } from "react"
import { FormTextFieldI } from "../interfaces"
import DateUI from "../DateUI/DateUI"
import dayjs, { Dayjs } from "dayjs"

const YearUI: FC<FormTextFieldI> = (props: FormTextFieldI) => {
  const formatChangeDate = (value: ChangeEvent<HTMLInputElement> | Dayjs | null) => {
    let val: string = ''

    if (value) {
      val = (value as Dayjs).format('YYYY')
    }
    return val
  }

  const extraProps = {
    ...(props?.props),
    views: ['year'],
    openTo: "year",
  }

  return <DateUI
    {...props}
    props={extraProps}
    maxDate={props?.props?.maxDate ?? dayjs().add(10, 'year')}
    formatChangeDate={formatChangeDate}
  />
}

export default YearUI
