import { useContext, useEffect, useState } from "react"
import Alert from "@mui/material/Alert"
import Grid from "@mui/material/Grid"
import styles from "./ContactForm.module.scss"
import DynamicForm from "@saas-organisms/Form/Dynamic/DynamicForm"
import { initValuesContactForm, schemaContactForm, validationSchemaContactForm } from "@saas-validations/contact"
import { initAllValues } from "@saas-validations/interfaces"
import { useTranslation } from "next-i18next"
import { setReportTypeList } from "@saas-reducers/Contact"
import ReducerContext from "@saas-reducers/ReducerContext"

const ContactForm = () => {
  const { t, i18n } = useTranslation(["translation", "error"])
  const { contactState: state, contactDispatch: dispatch } = useContext(ReducerContext)

  const currentLang = i18n.language ?? 'en-US'

  const [data, setData] = useState<{ errorLength: boolean, errorApi: string, success: boolean }>({ errorLength: false, errorApi: "", success: false })
  const { reportTypeList, reportType, extra, lastUrl } = state

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "/api/contact/list",
        {
          method: "GET",
          headers: { "Content-Type": "application/json;charset=utf-8" },
        }
      )
      const result = await response.json()
      dispatch(setReportTypeList({ reportTypeList: result }))
    }

    if (!reportTypeList || !reportTypeList.length) {
      fetchData().catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const validationSchema = validationSchemaContactForm
  const schema = schemaContactForm
  const list = new Array<{label: string, value: string}>()
  reportTypeList?.forEach((item) => {
    const label = item.name[currentLang] ?? item.name["en-US"] ?? null
    if (label) {
      list.push({
        label: label,
        value: item.type
      })
    }
  })
  schema[0].props = {
    ...(schema[0]?.props ?? {}),
    options: list
  }

  const initValue = initValuesContactForm
  initValue.encounteredProblem = reportType ?? initValue.encounteredProblem

  const handleSubmit = async ({ encounteredProblem, messageContent }: initAllValues) => {
    if (messageContent?.length === 0 || messageContent === undefined) {
      setData({ ...data, errorLength: true })
    } else {
      setData({ ...data, errorLength: false })
      const response = await fetch(
        "/api/contact",
        {
          method: "POST",
          headers: { "Content-Type": "application/json;charset=utf-8" },
          body: JSON.stringify({
            subject: (encounteredProblem ?? []).length ? encounteredProblem : ['other'],
            message: messageContent,
            locale: currentLang,
            lastUrl: lastUrl,
            extraInfo: extra
          })
        }
      )
      if (response.status == 200) {
        setData({ ...data, errorApi: "", success: true })
      }
      else {
        setData({ ...data, errorApi: (await response.json())["subinfo"] })
      }
    }
  }

  const buildAlert = () => {
    if (data.errorApi) {
      return <Alert className={styles['alert']} color='warning'>{`${t(data.errorApi, { ns: 'error' })}`}</Alert>
    } else if (data.errorLength) {
      return <Alert className={styles['alert']} color='warning'>{`${t('error:contactForm.messageContent')}`}</Alert>
    } else if (data.success) {
      return <Alert className={styles['success']} severity="success">{`${t("contactPage.success.content")}`}</Alert>
    }
    return null
  }

  return (
    <Grid className={styles['container']} container>
      <Grid item xs={12}>
        {buildAlert()}
        {!data.success && state.open && reportTypeList.length > 0 ?
          <>
            <h3 className={styles['title']}>{`${t('contactPage.title')}`}</h3>
            <p className={styles['context']}>{`${t('contactPage.context')}`}</p>
            <DynamicForm
              {...{
                validationSchema: validationSchema,
                initValue: initValue,
                schema: schema,
                showTitle: false,
                button: `${t("dynamiccard.button1")}`,
                handleSubmit
              }}
            />
          </> : null}
      </Grid>
    </Grid>
  )
}

export default ContactForm
