import { FC } from "react"
import Grid from "@mui/material/Unstable_Grid2"
import { HeaderLayoutPropsI } from "./interfaces"
import styles from './HeaderBaseLayout.module.scss'
import Divider from "@mui/material/Divider"
import Logo from "@saas-atoms/Logo/Logo"

const HeaderBaseLayout: FC<HeaderLayoutPropsI> = ({ left, middle, right, className }: HeaderLayoutPropsI) =>
  <Grid component='header' container justifyContent="space-between" alignItems="end" className={`${styles['header']} ${className ?? ''}`}>
    {left ? left : <Grid className={styles['logo']}>
      <Logo marginTop="0" marginLeft="0" />
    </Grid>}
    <Grid xs className={styles['middleHeader']}>
      {middle}
    </Grid>
    {right ? (
      <>
        <Divider light={true} sx={ { margin: '.5em 0' } } orientation="vertical" flexItem />
        <Grid className={styles['rightHeader']}>
          {right}
        </Grid>
      </>
    ) : null}
  </Grid>

export default HeaderBaseLayout
