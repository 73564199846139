import type { TFunction } from "next-i18next"

export const menuList = (t: TFunction) => [
  {
    label: `${t('menu.footer.privacy')}`,
    link: `${t('menu.footer.link.privacy')}`,
  },
  {
    label: `${t('menu.footer.term')}`,
    link: `${t('menu.footer.link.term')}`,
  },
]
