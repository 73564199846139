import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { IconEyeOff, IconEye, IconCircleCheck, IconKey } from '@tabler/icons-react'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import styles from './FormTextField.module.scss'
import { PasswordPropsI } from "./interfaces"
import { generatePassword } from "@saas-utils/generator"
import Tooltip from "@mui/material/Tooltip"

const Password = ({ field, rest, error, helperText, onChange, onBlur, label, size }: PasswordPropsI) => {
  const { t } = useTranslation(["translation", "pa", "com", "custom", "desc"])
  const { onInput, startAdornment } = rest.InputProps

  const [showPassword, setShowPassword] = useState(false)
  const [name, setName] = useState<string>("")
  const [constraintsStatus, setConstraintsStatus] = useState<{[key: string]: boolean}>({
    length: false,
    digit: false,
    upper: false,
    lower: false,
    special: false
  })

  useEffect(() => {
    const value = String(field.value ?? "")
    setName(value)
    validateConstraint(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value])

  const constraints: {
    [key: string]: {
      label: string,
      rule: RegExp,
    },
  } = {
    length: {
      label: t(`dynamicform.constraints.length`, { length: rest.props?.length ?? 0 }),
      rule: new RegExp(`.{${rest.props?.length ?? 0},}`),
    },
    digit: {
      label: t(`dynamicform.constraints.digit`),
      rule: new RegExp(/\d/),
    },
    upper: {
      label: t(`dynamicform.constraints.upper`),
      rule: new RegExp(/\p{Uppercase_Letter}/, 'u'),
    },
    lower: {
      label: t(`dynamicform.constraints.lower`),
      rule: new RegExp(/\p{Lowercase_Letter}/, 'u'),
    },
    special: {
      label: t(`dynamicform.constraints.special`),
      rule: new RegExp(/[-~!@#$%^&*()_=+[]{};:,.<>\/?€£µ]/),
    },
  }

  const validateConstraint = (value: string): boolean => {
    const tmp = constraintsStatus

    let isValid: boolean = true
    Object.entries(constraints).forEach(([col, config]) => {
      if (rest.props && rest.props[col]) {
        tmp[col] = config.rule.test(value)
        isValid &&= tmp[col]
      }
    })
    setConstraintsStatus(tmp)
    return isValid
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement> | { target: { name: string, value: string } }) => {
    onChange(e)
    setName(e.target.value)
    validateConstraint(e.target.value)
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const inputLabel = `${label}${rest.required ? ' *' : ''}`
  const constraintsFilter = Object.keys(rest.props ?? {}).filter((col) => rest.props && rest.props[col])

  const generatePass = () => {
    const newPass = generatePassword(rest.props.length, rest.props.digit, rest.props.lower, rest.props.upper, rest.props.special)
    const element = document.getElementById(rest.id)
    if (element) {
      const evt = { target: { name: field.name as string, value: newPass } }
      handleChange(evt)
    }
    if (!validateConstraint(newPass)) {
      generatePass()
    } else {
      setShowPassword(true)
    }
  }

  const buildGeneratePassword = () =>
    rest?.props?.generate && <Tooltip title={t('dynamicform.tooltip.suggestPasswd')}><IconButton
      aria-label="toggle password visibility"
      onClick={generatePass}
      onMouseDown={handleMouseDownPassword}
      edge="end"
    ><IconKey /></IconButton></Tooltip>

  const buildEye = () =>
    <Tooltip title={t(showPassword ? 'dynamicform.tooltip.hidePasswd' : 'dynamicform.tooltip.showPasswd')}>
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {showPassword ? <IconEyeOff /> : <IconEye />}
      </IconButton>
    </Tooltip>

  return (
    <div className={`${styles['container-password']} ${!constraintsFilter.length ? styles['margin'] : ''}`}>
      <FormControl variant="outlined" className={styles['content-password']}>
        <InputLabel htmlFor="outlined-adornment-password" style={ { color: helperText ? "red" : "#004AAD" } }>{inputLabel}</InputLabel>
        <OutlinedInput
          className={styles.textInputProps}
          id={rest.id}
          name={field.name}
          size={size}
          type={showPassword ? 'text' : 'password'}
          value={name}
          error={error}
          onChange={handleChange}
          onBlur={onBlur}
          onInput={onInput}
          startAdornment={startAdornment}
          autoComplete={rest?.props?.autoComplete ?? "off"}
          endAdornment={
            <InputAdornment position="end">
              <>
                {buildGeneratePassword()}
                {buildEye()}
              </>
            </InputAdornment>
          }
          label={inputLabel}
        />
        <FormHelperText style={ { color: "red" } }>{t(helperText as string)}</FormHelperText>
      </FormControl>
      {constraintsFilter.length > 0 && <div className="mt-2 flex flex-row flex-wrap justify-center">
        {constraintsFilter.map((col: string) =>
          constraints[col] ? <div key={col} data-col={col} className={`mx-2 text-xs ${constraintsStatus[col] ? 'text-success' : 'text-grey'}`}>
            <div className="flex items-center">
              <p className="mr-2"><IconCircleCheck size={24} stroke={1.5} /> </p>
              <p>{constraints[col].label}</p>
            </div>
          </div> : null
        )}
      </div>}
    </div>
  )
}

export default Password
