import { useState, ChangeEvent, useContext } from "react"
import { useTranslation } from 'next-i18next'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import styles from './FormTextField.module.scss'
import { DpeGesUIPropI } from "@saas-molecules/Form/interfaces"
import ReducerContext from "@saas-reducers/ReducerContext"
import { setData } from "@saas-reducers/Project"
import { TypeValues } from "@saas-organisms/Form/Project/interfaces"

const DpeGesUI = ({ handleDisplayArrow, field, form, display, defaultValueData, props }: DpeGesUIPropI) => {
  const { t } = useTranslation("pa")

  const data = props?.options ?? []
  const { projectState, projectDispatch: dispatch } = useContext(ReducerContext)
  const { dataCollected } = projectState
  const [hide, setHide] = useState<{[key: string]: boolean}>({ dpe: false, ges: false })

  const handleCheck = (ev: ChangeEvent<HTMLInputElement>, checked?: boolean) => {
    const param: Record<string, TypeValues> = {
      [ev.target.name]: !!checked
    }

    if (checked) {
      param[field.name] = `none-${field.name}`
      form.setFieldValue(field.name, `none-${field.name}`)
    }

    dispatch(setData({ dataCollected: param }))
  }

  const handleClose = (e: { currentTarget: { id: string }}) => {
    display[field.name] && setHide({ ...hide, [e.currentTarget.id]: true })
  }
  const handleOver = (e: { currentTarget: { id: string }}) => {
    setHide({ ...hide, [e.currentTarget.id]: false })
  }
  const checkFieldValue = `${field.name}Check`

  if (!defaultValueData || !(defaultValueData as string).length) {
    defaultValueData = `none-${field.name}`
  }

  return (
    <div className="grid grid-cols-1 place-items-center">
      <div className={`${styles['energy-title']}`}>
        <h3>{t(`pa:${field.name}.title`)}</h3>
      </div>
      <div
        className={`${styles['energy-values']}`}
        id={`main-${field.name}`}
        key={`${field.name}-${defaultValueData}`}
        style={ { zIndex: 100 } }>
        <div>
          {data && data.map((option: {value: string, label: string}, i: number) => (
            ((defaultValueData === option.value) || (defaultValueData !== option.value && display[field.name]))
              && !dataCollected[checkFieldValue] &&
              <div key={`${field.name}-${option.value}`} className={`level-${field.name}-${i}`}>
                <div id={field.name} onTouchMove={handleOver}
                  onClick={(e) => {
                    if (!hide[field.name]) {
                      handleDisplayArrow(field.name, true, form, option.value)
                      handleClose(e)
                    } else {
                      setHide({ ...hide, [e.currentTarget.id]: false })
                    }
                  }}
                  className={
                    defaultValueData !== option.value && field.name
                      ?
                      (hide[field.name] ? styles[`segment-${field.name}-${defaultValueData}-hide`] : styles[`segment-${field.name}-${option.value}`])
                      : styles[`segment-selected-${field.name}-${defaultValueData}`]}
                >
                  <div className={styles['label']}>
                    {defaultValueData === option.value && !['none-dpe', 'none-ges'].includes(`${defaultValueData}`) ?
                      t(`pa:${field.name}.${defaultValueData}`) :
                      t(option.label)}
                  </div>
                  <div className={styles['arrow-right']}></div>
                </div>
              </div>
          ))}
        </div>
      </div>
      <div className="">
        <FormGroup>
          <FormControlLabel
            control={<Checkbox name={checkFieldValue} onChange={handleCheck} checked={Boolean(dataCollected[checkFieldValue])}/>}
            label={t(`pa:${field.name}.noInfo`)}
          />
        </FormGroup>
      </div>
    </div>
  )
}

export default DpeGesUI