import ShapeTurquoise from './icons/shapeTurquoise.svg'
import ShapeBlue from './icons/shapeBlue.svg'
import Check from './icons/check.svg'
import NotChecked from './icons/notChecked.svg'
import HalfChecked from './icons/halfChecked.svg'
import CheckTertiary from './icons/checkTertiary.svg'
import NotCheckedTertiary from './icons/notCheckedTertiary.svg'
import HalfCheckedTertiary from './icons/halfCheckedTertiary.svg'

export {
  ShapeTurquoise,
  ShapeBlue,
  Check,
  NotChecked,
  HalfChecked,
  CheckTertiary,
  NotCheckedTertiary,
  HalfCheckedTertiary
}