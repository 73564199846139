import { ChangeEvent, FC } from "react"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import TextField from "@mui/material/TextField"
import { FormTextFieldI } from "../interfaces"
import { TimeUIPropsI } from "./interfaces"
import styles from "./TimeUI.module.scss"
import { useTranslation } from "next-i18next"
import { useTheme } from "@mui/material/styles"
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/fr'
import 'dayjs/locale/es'
import 'dayjs/locale/es-us'
import { getIn } from "formik"
import { mappingLocaleDayjs } from "@saas-utils/tools"

const TimeUI: FC<FormTextFieldI> = ({
  label,
  defaultValueData,
  field,
  size,
  form,
  error,
  helperText,
  classnamehasvalue,
  handleChange = () => null,
  formatChangeDate,
  props
}: FormTextFieldI & TimeUIPropsI) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation(["translation", "pa", "com", "custom", "desc"])

  const errorMessage: string = getIn(form.errors, field.name) as string
  const setClassValue = (val: boolean) => val ? (classnamehasvalue ?? '') : ''
  const hasError = error ?? Boolean(errorMessage)
  const hasHelperText = helperText ?? (hasError ? t(errorMessage) : undefined)

  const formatValue = (val: string) => val ? dayjs(`1970-01-01 ${val}`) : null

  const defaultFormatChangeDate = (value: ChangeEvent<HTMLInputElement> | Dayjs | null): string => {
    let val: string = ''

    if (value) {
      val = (value as Dayjs).format('HH:mm')
    }
    return val
  }

  const onChange = (value: ChangeEvent<HTMLInputElement> | Dayjs | null) => {
    const handle = formatChangeDate ?? defaultFormatChangeDate
    handleChange(handle(value))
  }

  return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={mappingLocaleDayjs[i18n.language]}>
    <TimePicker
      label={label}
      value={formatValue((defaultValueData ?? field.value) as string)}
      onChange={onChange}
      {...props}
      renderInput={(params) => <TextField
        {...params}
        size={size}
        className={`${styles['textInputTimeProps']} ${setClassValue(!!(defaultValueData ?? field.value))}`}
        name={field.name}
        InputLabelProps={ {
          sx: {
            color: theme.palette.primary.main
          }
        } }
        error={hasError}
        helperText={hasHelperText}
      />}
    />
  </LocalizationProvider>
}

export default TimeUI
