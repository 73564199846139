import { FC, ReactNode } from "react"
import Link from "next/link"
import { useTranslation } from "next-i18next"
import Box from "@mui/material/Box"
import ListItemIcon from "@mui/material/ListItemIcon"
import { useTheme } from '@mui/material/styles'
import Menu from "@mui/material/Menu"
import { IconUserCircle } from '@tabler/icons-react'
import styles from './MenuAccount.module.scss'
import { menuList } from "./menuList.config"
import type { MenuAccountPropsI } from "./interfaces"
import { MenuUIItemPropsI } from "@lib-ui/molecules/MenuUI/interfaces"
import ButtonUI from "@lib-ui/atoms/ButtonUI/ButtonUI"
import MenuUI from "@lib-ui/molecules/MenuUI/MenuUI"
import useSWR from "swr"
import { SubscriptionPropsI } from "@saas-repositories/allready/interfaces"

const MenuAccount: FC<MenuAccountPropsI> = ({
  user = null,
  light = true,
  anchorEl,
  handleClick,
  handlerSignOut
}: MenuAccountPropsI) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation("translation")
  const { data: subscriptions, isLoading } = useSWR<Array<SubscriptionPropsI>>('/api/user/subscriptions')

  const openMenu = Boolean(anchorEl)

  const formatIcon = (icon?: ReactNode | string): ReactNode|null =>
    icon ? <ListItemIcon data-testid="icon">{icon}</ListItemIcon> : null

  const formatLink = (e: MenuUIItemPropsI): ReactNode | null => {
    return !e.link ? <>{formatIcon(e.icon)} {e.label}</> :
      <Link href={e.link} locale={i18n.language} className={styles['link-container']}>
        <div className={styles['link-content']}>{formatIcon(e.icon)} {e.label}</div>
      </Link>
  }

  const menu = menuList(user, t, light, handlerSignOut, theme, !isLoading ? (subscriptions ?? []) : [])

  return <>
    <ButtonUI
      className={styles['menu-button']}
      label={<>
        <IconUserCircle color={theme.palette.primary.main} stroke={1.25} />
        <Box sx={ { display: { xs: "none", sm: "block" } } }>{t('menu.account.button')}</Box>
      </>}
      variant="text"
      // @ts-ignore
      color="dark"
      onClick={handleClick}
      data-testid="menu-account"
    />
    <Menu
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleClick}
      className={styles['menu-content']}
      MenuListProps={ {
        'aria-labelledby': 'account-button',
      } }
    >
      <MenuUI
        list={false}
        items={menu}
        hoverItems={ {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.tertiary.main,
        } }
        customFormatLink={formatLink}
        data-testid="menu-account-list"
      />
    </Menu>
  </>
}

export default MenuAccount
