import { FC } from "react"
import { BreadcrumbsItemPropsI, BreadcrumbsPropsI } from "./interfaces"
import MuiBreadcrumbs from "@mui/material/Breadcrumbs"
import { IconHome2 } from "@tabler/icons-react"
import { useTranslation } from "next-i18next"
import { useTheme } from "@mui/material/styles"
import Link from "next/link"
import styles from "./Breadcrumbs.module.scss"
import useMediaQuery from "@mui/material/useMediaQuery"

const Breadcrumbs: FC<BreadcrumbsPropsI> = ({ items = [] }) => {
  const { t, i18n } = useTranslation(["translation", "meta", "stripe", "pa", "com", "custom", "desc", "error"])
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.up('md'))

  const max = items.length - 1
  const newItems: BreadcrumbsItemPropsI[] = items
    .filter((_item, index) => matchDownMd || index === max)
    .map(item => ({
      ...item,
      label: typeof item.label === 'string' ? t(item.label, item?.opt ?? {}) : item.label,
    }))

  newItems.unshift({
    label: <>
      <IconHome2 color={theme.palette.primary.main} stroke={1.25} />
      <span className="hidden sm:inline">{t('menu.header.home')}</span>
    </>,
    link: '/'
  })

  const buildItem = (item: BreadcrumbsItemPropsI, idx: number) => {
    if (item.link) {
      return <Link
        href={item.link}
        key={idx}
        locale={i18n.language}
        className={styles['link-container']}
      >
        <span className={styles['label']}>{item.label}</span>
      </Link>
    }
    return <span key={idx} color={theme.palette.primary.main}>{item.label}</span>
  }

  return <MuiBreadcrumbs separator="›" aria-label="breadcrumb">
    {newItems.map(buildItem)}
  </MuiBreadcrumbs>
}

export default Breadcrumbs
