import { ChangeEvent, FC, useRef, useState } from "react"
import { FormTextFieldI } from "../interfaces"
import {
  IconCircleCheck,
  IconCircleX,
  IconCloudUpload,
  IconRotateClockwise2, IconTrash
} from "@tabler/icons-react"
import CustomFieldUI from "../CustomFieldUI/CustomFieldUI"
import stylesField from "@saas-atoms/Field/Field.module.scss"
import { clearDelay, delay } from "@saas-utils/tools"
import styles from "./S3UploadUI.module.scss"
import TextField from "@mui/material/TextField"
import { useTranslation } from "next-i18next"
import { ValuesUIPropsI } from "@saas-atoms/Field/S3UploadUI/interfaces"
import { useTheme } from "@mui/material/styles"
import Tooltip from "@mui/material/Tooltip"

const S3UploadUI: FC<FormTextFieldI> = (params) => {
  const {
    defaultValueData,
    field,
    handleChange = field?.onChange,
    props
  } = params
  const theme = useTheme()
  const { t } = useTranslation(["translation", "pa", "com", "custom", "desc"])
  const inputRef = useRef<HTMLInputElement>(null)
  const inputLabelRef = useRef<HTMLInputElement>(null)

  const [active, setActive] = useState<boolean>(false)
  const [canBlur, setCanBlur] = useState<boolean>(true)
  const [defaultInitValue, setValue] = useState<ValuesUIPropsI|null>((defaultValueData as unknown as ValuesUIPropsI) ?? field?.value ?? null)
  const [state, setState] = useState<"none"|"start"|"success"|"error">("none")

  const handleUpload = async (currentFile: File | null) => {
    if (currentFile) {
      setState("start")

      const formData = new FormData()
      formData.append("file", currentFile)
      formData.append("acl", props?.acl ?? "private")

      try {
        const result = await fetch('/api/upload/file', {
          method: "POST",
          body: formData,
        })

        const data = await result.json()

        if (data.success) {
          const val = { name: data.name as string, path: data.file, uploading: false }
          setState("success")
          setValue(val)
          handleChange(val)
          await delay(() => setState("none"), 2000)()
        } else {
          setState("error")
        }
      } catch (error) {
        setState("error")
      }
    }
  }

  const handleClickLabel = () => {
    if (state != "start") {
      setActive(true)
      inputLabelRef.current?.focus()
      inputRef.current?.click()
    }
  }

  const handleChangeUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const currentFile = e.target.files ? e.target.files[0] : null
    const val = { name: currentFile?.name ?? null, uploading: true }
    setValue(val)
    handleChange(val)

    if (currentFile) {
      const mimeTypes = props?.type ?? []
      if (!mimeTypes.length || mimeTypes.includes(currentFile?.type)) {
        handleUpload(currentFile).catch(console.error)
      } else if (mimeTypes.length) {
        // error
      }
    } else {
      handleChange(null)
    }
  }

  const handleRemoveFile = () => {
    setActive(true)
    inputLabelRef.current?.focus()
    setValue(null)
    handleChange(null)
  }

  return <CustomFieldUI
    {...params}
    hasValue={(defaultInitValue?.name ?? null) !== null}
    active={active}
    className={styles['container']}
    startIcon={<IconCloudUpload />}
    required={params.required}
    handleClickLabel={handleClickLabel}
  >
    <div className={styles['content-label']}>
      <input
        ref={inputLabelRef}
        className={styles['label']}
        type="text"
        accept={props?.type.join(',') ?? undefined}
        value={defaultInitValue?.name?.length ? defaultInitValue.name : `${t("field.s3UploadUI.no-file")}`}
        readOnly={true}
        onClick={handleClickLabel}
        onBlur={() => {
          canBlur && delay(() => setActive(false), 100)()
        }}
        onFocus={() => {
          clearDelay()
          setActive(true)
        }}
      />
      <span className={styles['state']}>
        {state === "start" && <IconRotateClockwise2 stroke={1.25} color={theme.palette.info.main} className={styles['spin']} />}
        {state === "success" && <IconCircleCheck stroke={1.25} color={theme.palette.success.main} onMouseEnter={() => setState("none")}/>}
        {state === "error" && <IconCircleX stroke={1.25} color={theme.palette.error.main} />}
        {state === "none" && (defaultInitValue?.name ?? null) !== null && <>
          <Tooltip title={t("field.s3UploadUI.trash")} placement="top">
            <span>
              <IconTrash className="cursor-pointer" stroke={1.25} color={theme.palette.grey[800]} onClick={handleRemoveFile} />
            </span>
          </Tooltip>
        </>}
      </span>
    </div>
    <TextField
      className={`${stylesField['fieldProps']} ${styles['field_container']}`}
      inputRef={inputRef}
      type="file"
      hidden={true}
      onClickCapture={() => {
        setCanBlur(false)
        delay(() => setCanBlur(true), 200)()
      }}
      onChange={handleChangeUpload}
      InputProps={ {
        autoComplete: 'nope',
        className: styles['field'],
      } }
      inputProps={ {
        accept: props?.type.join(',') ?? undefined
      } }
    />
  </CustomFieldUI>
}

export default S3UploadUI
