import { FC } from 'react'
import Box from "@mui/material/Box"
import { CardSimpleUIPropsI } from './interfaces'
import styles from './CardSimpleUI.module.scss'

const CardSimpleUI: FC<CardSimpleUIPropsI> = ({
  title = 'Reinitialize your Password',
  sx,
  colorTitle = '#004AAD',
  className = '',
  component = 'div',
  children,
  onClick,
  ...props
}: CardSimpleUIPropsI) => {

  const defaultNode = <div data-testid='cardsimple-random-node' className={styles["cardSimple-paragraph"]}>
    Enter your email address, so that we send the instructions to follow for reinitializing your password
  </div>

  const formatTitle = () => typeof title !== 'string' ? title : <h1 style={ { color: colorTitle } }>{title}</h1>
  return <Box
    component={component}
    className={`${styles['cardSimple-container']} ${className} `}
    sx={sx}
    onClick={onClick}
    {...props}
  >
    {title && formatTitle()}
    {children ?? defaultNode}
  </Box>
}

export default CardSimpleUI
