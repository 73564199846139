import { ReactNode } from "react"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { menuList } from "./menuList.config"
import styles from "./MenuFooter.module.scss"

const MenuFooter = () => {
  const { i18n, t } = useTranslation("translation")

  const formatLabel = (label?: ReactNode | string): ReactNode | null =>
    label ? <span data-testid="label" className={styles['label']}>{label}</span> : null

  const items = menuList(t)
  const date = new Date()
  return <>
    <div className={styles['menu']} data-testid="menu-header">
      {items && items.map(e => <Link href={e.link} locale={i18n.language} key={e.label} className={styles['link-container']} target="_blank">
        {formatLabel(e.label)}
      </Link>)}
    </div>
    <span className={styles['copyright']}>© {date.getFullYear()}, Labsense</span>
  </>
}

export default MenuFooter
