import { ReactNode, useRef } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import Alert from '@mui/material/Alert'
import { Formik, FormikHelpers, FormikProps, Field, Form } from "formik"
import { DataValueFormPropsI, DynamicFormPropsI, DynamicSchemaTypePropsI } from './interfaces'
import styles from './DynamicForm.module.scss'
import FormTextField from "@saas-molecules/Form/FormTextField"
import ButtonUI from "@lib-ui/atoms/ButtonUI/ButtonUI"

const DynamicForm = ({
  validationSchema,
  initValue,
  schema,
  handleSubmit = () => console.log('submit'),
  button,
  className = '',
  buttonClassName = '',
  showTooltip = true,
  children,
  handleChange,
  innerRef,
}: DynamicFormPropsI) => {
  const { t } = useTranslation(["translation", "pa", "com", "custom", "desc", "stripe"])
  const router = useRouter()
  const formikRef = useRef<FormikProps<DataValueFormPropsI>>(null)

  const { error } = router.query

  const onSubmit = (values: DataValueFormPropsI, formikHelpers: FormikHelpers<DataValueFormPropsI>) => {
    handleSubmit(values, formikHelpers)
    formikHelpers.setSubmitting(false)
  }

  const buildButton = (isSubmitting: boolean): ReactNode => {
    if (typeof button === 'string') {
      return <div className={styles['button']}>
        <ButtonUI
          className={`contained ${buttonClassName ?? ''}`}
          type="submit"
          variant="contained"
          size="large"
          disabled={isSubmitting}
          label={button}
        />
      </div>
    }
    return button
  }

  const defaultHandleChange = handleChange ? handleChange : (value: string | number | boolean | Array<boolean | string | number>, name: string, formikProps: FormikProps<DataValueFormPropsI>) => {
    formikProps.setFieldValue(name, value)
  }

  const buildField = (element: DynamicSchemaTypePropsI, formikProps: FormikProps<DataValueFormPropsI>) => {
    const col = element?.col
    let className = ''
    if (col) {
      if (col !== 6) {
        className = `basis-${element.col}/6`
      } else {
        className = 'basis-full'
      }
    }

    return <div className={`min-w-[12em] px-2 pb-4 ${element?.col ? className : ''} ${element?.className ?? ''}`} key={element.name}>
      <Field
        fullWidth={true}
        style={ { marginBottom: '1em' } }
        required={element?.required ?? false}
        handleChange={(value: string | number | boolean | Array<boolean | string | number>) => defaultHandleChange(value, element.name, formikProps)}
        id={element.name}
        data-testid={element.name}
        name={element.name}
        label={t(element.label)}
        component={FormTextField}
        type={element.type}
        size={element.size}
        props={element?.props}
        special={ { nested: null } }
        placeholder={element?.placeholder}
        showtooltip={showTooltip.toString()}
        disabled={element.disabled ?? false}
        InputProps={ {
          style: { textAlign: 'center', backgroundColor: 'white' },
        } }
      />
    </div>
  }

  return <Formik<DataValueFormPropsI>
    initialValues={initValue}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    innerRef={innerRef ?? formikRef}
  >
    {(formikProps: FormikProps<DataValueFormPropsI>) => (
      <Form className={`${styles['container-form']} ${className ?? ""}`}>
        <div className={`flex flex-row w-full flex-wrap`}>
          {error && <Alert color='warning'>{t(error, { ns: 'error' })}</Alert>}
          {schema.map((element: DynamicSchemaTypePropsI) => buildField(element, formikProps))}
        </div>
        {children ? <div className="px-2 basis-full">{children}</div> : null}
        {button ? <div className="px-2 basis-full">{buildButton(formikProps.isSubmitting)}</div> : null}
      </Form>
    )}
  </Formik>
}

export default DynamicForm
