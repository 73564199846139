import { FC, useRef, useState } from "react"
import { FormTextFieldI, ValuePropsI } from "../interfaces"
import styles from "./RatingUI.module.scss"
import stylesField from "../Field.module.scss"
import { useTranslation } from "next-i18next"
import { useTheme } from "@mui/material/styles"
import Rating from "@mui/material/Rating"
import { IconStar, IconStarOff } from "@tabler/icons-react"
import Tooltip from "@mui/material/Tooltip"
import CustomFieldUI from "@saas-atoms/Field/CustomFieldUI/CustomFieldUI"
import TextField from "@mui/material/TextField"
import { clearDelay, delay } from "@saas-utils/tools"

const RatingUI: FC<FormTextFieldI> = (params) => {
  const {
    label,
    defaultValueData,
    field,
    size,
    handleChange = () => null,
    required,
    props
  } = params
  const theme = useTheme()
  const { t } = useTranslation(["translation", "pa", "com", "custom", "desc"])
  const inputRef = useRef<HTMLInputElement>()
  const [active, setActive] = useState<boolean>(false)
  const [defaultInitValue, setValue] = useState<number | "">((defaultValueData ?? field.value) === "" ? "" : Number(defaultValueData ?? field.value))

  const max = Number(props?.max ?? 5)
  const change = (value: number | "") => {
    setValue(value)
    handleChange(value)
  }

  const handleChangeRating = (value: ValuePropsI) => {
    const regex = /^\d*$/
    if (value === "") {
      change("")
    } else if (regex.test(value as string)) {
      let valNumber = Number(value)
      if (valNumber > max) {
        valNumber = max
      }

      change(valNumber)
    }
  }

  const handleClickLabel = () => {
    setActive(true)
    inputRef.current?.focus()
  }

  return <CustomFieldUI
    {...params}
    hasValue={defaultInitValue !== ""}
    className={styles['ratingContainer']}
    active={active}
    required={required}
    handleClickLabel={handleClickLabel}
  >
    <div className="flex flex-row items-center py-1.5 px-3">
      <div className="basis-3/6">
        <Rating
          sx={ {
            '& .MuiRating-iconFilled': {
              fill: theme.palette.primary.main,
              color: theme.palette.primary.main,
            },
            '& .MuiRating-iconHover': {
              fill: theme.palette.primary.light,
              color: theme.palette.primary.light,
            },
          } }
          className={stylesField['fieldProps']}
          value={defaultInitValue === "" ? null : defaultInitValue}
          name={field.name}
          max={max}
          onChange={(event, value) => {
            inputRef.current?.focus()
            return handleChangeRating(value ?? "")
          }}
          onClick={() => clearDelay()}
          size={size}
          icon={<IconStar fill="inherit" color="inherit" stroke={1.25} />}
          emptyIcon={<IconStar stroke={1.25} />}
          {...props}
        />
      </div>
      <div className="basis-2/6">
        <TextField
          className={stylesField['fieldProps']}
          value={defaultInitValue}
          inputRef={inputRef}
          placeholder={label}
          onChange={(event) => handleChangeRating(event.target.value)}
          onBlur={(event) => {
            delay(() => setActive(false), 100)()
            handleChangeRating(event.target.value)
          }}
          onFocus={() => {
            clearDelay()
            setActive(true)
          }}
          InputProps={ {
            autoComplete: 'nope',
            className: styles['field']
          } }
        />
      </div>
      {defaultInitValue !== "" ? (
        <div className="basis-1/6 text-right">
          <Tooltip arrow title={t('field.ratingUI.clear')}>
            <span>
              <IconStarOff
                className={styles['ratingClear']}
                color={theme.palette.primary.main}
                stroke={1.25}
                onClick={() => {
                  clearDelay()
                  inputRef.current?.focus()
                  handleChangeRating("")
                }}
              />
            </span>
          </Tooltip>
        </div>
      ) : null}
    </div>
  </CustomFieldUI>
}

export default RatingUI
