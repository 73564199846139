import { FC, useState } from "react"
import { FormTextFieldI } from "../interfaces"
import { useTranslation } from "next-i18next"
import { getIn } from "formik"
import FormHelperText from "@mui/material/FormHelperText"
import styles from "./CustomFieldUI.module.scss"
import stylesField from "../Field.module.scss"
import { CustomFieldUIPropsI } from "./interfaces"
import Tooltip from "@mui/material/Tooltip"

const CustomFieldUI: FC<FormTextFieldI & CustomFieldUIPropsI> = ({
  label,
  field,
  form,
  error,
  helperText,
  classnamehasvalue,
  required,
  active,
  handleClickLabel,
  className = "",
  hasValue = false,
  openTooltip = true,
  children,
  ...props
}: FormTextFieldI & CustomFieldUIPropsI) => {
  const { t } = useTranslation(["translation", "pa", "com", "custom", "desc"])
  const [open, setOpen] = useState(false)

  const errorMessage: string = getIn(form.errors, field.name) as string
  const hasError = error ?? Boolean(errorMessage)
  const hasHelperText = hasError ? t(errorMessage) : helperText
  const inputLabel = `${label}${required ? ' *' : ''}`

  const setClassValue = (val: boolean) => val ? (classnamehasvalue ?? '') : ''

  const onClickField = () => {
    if (!active && !hasValue) {
      handleClickLabel()
    }
  }

  return <Tooltip describeChild title={label} arrow open={openTooltip && open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
    <div
      className={`${className} ${styles['container']} ${hasError ? stylesField['error'] : ''}`}
      data-active={active ? 'true' : 'false'}
      data-focus={active || hasValue ? 'true' : 'false'}
    >
      {inputLabel && <label className={styles['label']} onClick={handleClickLabel}>
        <>
          {props.startIcon && <>{props.startIcon} </>}
          {inputLabel}
        </>
      </label>}
      <fieldset className={`${styles['fieldset']} ${setClassValue(hasValue)}`}>
        {inputLabel && <legend className={styles['legend']}>
          <span>
            <>
              {props.startIcon && <>{props.startIcon} </>}{inputLabel}
            </>
          </span>
        </legend>}
        <div className={styles['field']} onClick={onClickField}>
          {children}
        </div>
      </fieldset>
      <FormHelperText className={stylesField['helperText']}>{hasHelperText}</FormHelperText>
    </div>
  </Tooltip>
}

export default CustomFieldUI
