import { ChangeEvent, FC } from "react"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import TextField from "@mui/material/TextField"
import { DateUIPropsI } from "./interfaces"
import { FormTextFieldI } from "../interfaces"
import styles from "./DateUI.module.scss"
import { useTranslation } from "next-i18next"
import { useTheme } from "@mui/material/styles"
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/fr'
import 'dayjs/locale/es'
import 'dayjs/locale/es-us'
import { getIn } from "formik"
import { mappingLocaleDayjs } from "@saas-utils/tools"

const DateUI: FC<FormTextFieldI> = ({
  label,
  defaultValueData,
  field,
  size,
  form,
  error,
  helperText,
  classnamehasvalue,
  handleChange = () => null,
  formatChangeDate,
  required,
  props
}: FormTextFieldI & DateUIPropsI) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation(["translation", "pa", "com", "custom", "desc"])

  const errorMessage: string = getIn(form.errors, field.name) as string
  const setClassValue = (val: boolean) => val ? (classnamehasvalue ?? '') : ''
  const hasError = error ?? Boolean(errorMessage)
  const hasHelperText = helperText ?? (hasError ? t(errorMessage) : undefined)

  const formatValue = (val: string) => dayjs(val)
  const inputLabel = `${label}${required ? ' *' : ''}`

  const defaultFormatChangeDate = (value: ChangeEvent<HTMLInputElement> | Dayjs | null): string => {
    let val: string = ''

    if (value) {
      val = (value as Dayjs).format('YYYY-MM-DD')
    }
    return val
  }

  const onChange = (value: ChangeEvent<HTMLInputElement> | Dayjs | null) => {
    const handle = formatChangeDate ?? defaultFormatChangeDate
    handleChange(handle(value))
  }
  const minDate = props?.minDate === 'now' ? dayjs() : props?.minDate
  const maxDate = props?.maxDate === 'now' ? dayjs() : (props?.maxDate ?? dayjs().add(2, 'year'))

  return <LocalizationProvider
    dateAdapter={AdapterDayjs}
    adapterLocale={mappingLocaleDayjs[i18n.language]}
    localeText={ {
      todayButtonLabel: `${t('field.dateUI.today')}`
    } }
  >
    <DatePicker
      label={inputLabel}
      value={formatValue((defaultValueData ?? field.value) as string)}
      onChange={onChange}
      {...props}
      minDate={minDate}
      maxDate={maxDate}
      renderInput={(params) => <TextField
        {...params}
        size={size}
        className={`${styles['textInputDateProps']} ${setClassValue(!!(defaultValueData ?? field.value))}`}
        name={field.name}
        InputLabelProps={ {
          sx: {
            color: theme.palette.primary.main
          }
        } }
        error={hasError}
        helperText={hasHelperText}
      />}
      componentsProps={ {
        actionBar: {
          actions: ['today'],
        },
      } }
    />
  </LocalizationProvider>
}

export default DateUI
