import { FC, useRef, useState } from "react"
import { FormTextFieldI, NestedPropsI, SelectOptionPropsI } from "../interfaces"
import styles from "./RangeUI.module.scss"
import stylesField from "../Field.module.scss"
import { useTranslation } from "next-i18next"
import Grid from '@mui/material/Unstable_Grid2'
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import MenuItem from "@mui/material/MenuItem"
import CustomFieldUI from "@saas-atoms/Field/CustomFieldUI/CustomFieldUI"

const RangeUI: FC<FormTextFieldI> = (params) => {
  const {
    defaultValueData,
    defaultValueSpecial,
    field,
    handleChange = field?.onChange,
    handleNestedChange = field?.onChange,
    nested,
    props
  } = params
  const { t } = useTranslation(["translation", "pa", "com", "custom", "desc"])

  const [active, setActive] = useState<boolean>(false)
  const inputElement = useRef<HTMLInputElement>(null)
  const [minVal, setMinVal] = useState<string>((defaultValueData as { min: string })?.min ?? '')
  const [maxVal, setMaxVal] = useState<string>((defaultValueData as { max: string })?.max ?? '')

  const handleChangeRange = (value: string, type: 'min' | 'max') => {
    const regex = /^-?\+?\d*[,.]?\d*$/
    const valString = value.replace(/,/, '.')
    const valNumber = Number(valString)
    const newVal = valString === String(valNumber) ? valNumber : valString

    if (value === "" || regex.test(value)) {
      if (type === 'min') {
        setMinVal(value)
        handleChange({ min: value === "" ? "" : newVal, max: maxVal })
      }
      else {
        setMaxVal(value)
        handleChange({ min: minVal, max: value === "" ? "" : newVal })
      }
    }
  }

  const buildMenuNested = (nested: NestedPropsI) => {
    const data: Array<SelectOptionPropsI> = nested.props.options ?? []
    return data.map((option) => (
      <MenuItem key={option.value} value={option.value} sx={ {
        backgroundColor: 'white',
        ":hover": { backgroundColor: "lightgrey" } } }
      >
        {t(option.label)}
      </MenuItem>
    ))
  }

  const bothRequired = props?.min?.required && props?.max?.required
  const oneRequired = (props?.min?.required || props?.max?.required) && !bothRequired
  const labelField = {
    min: `${t(props?.min?.label, "min")}${props?.min?.required && !(bothRequired && params.required) ? ' *' : ''}`,
    max: `${t(props?.max?.label, "max")}${props?.max?.required && !(bothRequired && params.required) ? ' *' : ''}`,
  }

  const handleClickLabel = () => {
    setActive(true)
    if (inputElement.current) {
      inputElement.current.focus()
    }
  }

  return <CustomFieldUI
    {...params}
    hasValue={minVal !== '' || maxVal !== ''}
    className={styles['container']}
    active={active}
    required={oneRequired || params.required}
    handleClickLabel={handleClickLabel}
  >
    <Grid container alignItems="center" className={styles['content']}>
      <Grid xs>
        <TextField
          inputRef={inputElement}
          placeholder={labelField.min}
          className={stylesField['fieldProps']}
          // defaultValue={defaultValueData?.min ?? ""}
          value={minVal}
          onChange={(event) => handleChangeRange(event.target.value, 'min')}
          onBlur={(event) => {
            setActive(false)
            return handleChangeRange(event.target.value, 'min')
          }}
          onFocus={() => {
            setActive(true)
          }}
          InputProps={ {
            autoComplete: 'nope',
            className: `${styles['field']} ${styles['min']}`
          } }
        />
      </Grid>
      <Grid xs>
        <TextField
          placeholder={labelField.max}
          className={stylesField['fieldProps']}
          // defaultValue={defaultValueData?.max ?? ""}
          value={maxVal}
          onChange={(event) => handleChangeRange(event.target.value, 'max')}
          onBlur={(event) => {
            setActive(false)
            return handleChangeRange(event.target.value, 'max')
          }}
          onFocus={() => {
            setActive(true)
          }}
          InputProps={ {
            autoComplete: 'nope',
            className: styles['field'],
            endAdornment: nested && <InputAdornment position="end">
              <TextField variant="standard" name={nested.name} select value={defaultValueSpecial}
                onChange={(event) => handleNestedChange(event.target.value)}
                onBlur={(event) => handleNestedChange(event.target.value)}
                InputProps={ {
                  disableUnderline: true
                } }
                defaultValue={defaultValueSpecial}
                SelectProps={ { IconComponent: () => null, className: stylesField['endAdornment'], } }
              >
                {buildMenuNested(nested)}
              </TextField>
            </InputAdornment>
          } }
        />
      </Grid>
    </Grid>
  </CustomFieldUI>
}

export default RangeUI
