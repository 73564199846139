import { FC } from "react"
import { useTranslation } from "next-i18next"
import Link from 'next/link'
import LogoAllready from '@saas-icons/logo-allready.ico.svg'
import { LogoPropsI } from "./interfaces"
import styles from './Logo.module.scss'

const Logo: FC<LogoPropsI> = ({
  marginTop = ".5em",
  marginRight = "0",
  marginBottom = "0",
  marginLeft = "1em",
}: LogoPropsI) => {
  const { i18n } = useTranslation()
  const locale: string = i18n.language

  return (
    <div className={styles['logo']} style={ { marginTop, marginRight, marginBottom, marginLeft } } data-testid="logo-saas">
      <Link href="/" locale={locale}>
        <LogoAllready />
      </Link>
    </div>
  )
}

export default Logo
