import { MouseEvent } from "react"
import type { TFunction } from "next-i18next"
import {
  IconSettings,
  IconAdjustmentsHorizontal,
  IconUser,
  IconCreditCard,
  IconLogout,
  IconAlertTriangle
} from '@tabler/icons-react'
import { Theme } from "@mui/material/styles"
import { User } from "next-auth"
import { MenuUIDividerPropsI, MenuUIItemPropsI } from "@lib-ui/molecules/MenuUI/interfaces"
import { SubscriptionPropsI } from "@saas-repositories/allready/interfaces"

export const menuList = (
  user: User | null,
  t: TFunction,
  light: boolean,
  handlerSignout: (e: MouseEvent<HTMLElement>) => void,
  theme: Theme,
  subscriptions: Array<SubscriptionPropsI>
): Array<MenuUIDividerPropsI|MenuUIItemPropsI> => {
  let menus: Array<MenuUIDividerPropsI|MenuUIItemPropsI> = [
    {
      idx: 'name',
      label: <>{t('menu.account.hello')}&nbsp;<strong>{user?.firstname ?? ""}</strong></>
    },
    {
      divider: true
    }
  ]

  if (!light) {
    menus.push({
      idx: 'profil',
      label: `${t('menu.account.profil')}`,
      link: '/account/settings',
      icon: <IconSettings color={theme.palette.primary.main} stroke={1.25} />,
    },
    {
      idx: 'localization',
      label: `${t('menu.account.preferences')}`,
      link: '/preferences/localization',
      icon: <IconAdjustmentsHorizontal color={theme.palette.primary.main} stroke={1.25} />,
    })
    const id = process.env.NEXT_PUBLIC_ROLES_MANAGE_ID ?? ""
    const canManage = (user?.roles ?? []).includes(id)
    const idAWS = process.env.NEXT_PUBLIC_ROLES_MANAGE_AWS_ID ?? ""
    const canManageUser = (user?.roles ?? []).includes(idAWS)
    const unpaid = Number((subscriptions ?? []).filter(items => ["past_due", "unpaid", "incomplete"].includes(items.status))?.length) > 0

    if (canManage) {
      menus.push({
        idx: 'users',
        label: `${t('menu.account.profiles')}`,
        link: '/preferences/users',
        icon: <IconUser color={theme.palette.primary.main} stroke={1.25} />,
      })
      menus.push({
        idx: 'customer',
        label: t('menu.account.billing'),
        link: '/billing',
        icon: unpaid ? <IconAlertTriangle color={theme.palette.error.main} stroke={1.25} /> :
          <IconCreditCard color={theme.palette.primary.main} stroke={1.25} />,
      })
    } else if (canManageUser) {
      menus.push({
        idx: 'users',
        label: `${t('menu.account.profiles')}`,
        link: '/preferences/users',
        icon: <IconUser color={theme.palette.primary.main} stroke={1.25} />,
      })
    }
  }

  menus.push({
    idx: 'logout',
    label: `${t('menu.account.sign-out')}`,
    onClick: handlerSignout,
    icon: <IconLogout color={theme.palette.primary.main} stroke={1.25} style={ { marginLeft: "3px", marginRight: "0.4em" } } />
  })
  return menus
}
