import { FC, useContext, useEffect } from 'react'
import { useFormikContext } from 'formik'
import TextField from '@mui/material/TextField'
import styles from './FormTextField.module.scss'
import { CalcI } from "./interfaces"
import ReducerContext from "@saas-reducers/ReducerContext"
import { setData } from "@saas-reducers/Project"

const Calc: FC<CalcI> = ({
  field,
  defaultValueData,
  calc,
  error,
  helperText,
  label,
  ...props
}: CalcI) => {
  const { setFieldValue } = useFormikContext()
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const { handleNestedChange, defaultValueSpecial, ...rest } = props

  const { projectState: state, projectDispatch: dispatch } = useContext(ReducerContext)
  const { dataCollected } = state

  useEffect(() => {
    if(calc) {
      if(dataCollected[calc.numerator] || dataCollected[calc.denominator]) {
        const result = Math.floor((Number(dataCollected[calc.numerator]) / Number(dataCollected[calc.denominator])) * 100)
        setFieldValue(field.name, result)
        dispatch(setData({
          dataCollected: {
            [field.name]: result
          }
        }))
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCollected[field.name], dataCollected[calc!.denominator], dataCollected[calc!.numerator]])

  return (
    <TextField
      key={defaultValueData as number ?? dataCollected[field.name] as number}
      variant="outlined"
      error={error}
      helperText={helperText}
      label={label}
      {...rest}
      type="number"
      defaultValue={defaultValueData ?? dataCollected[field.name]}
      name={field.name}
      InputProps={ {
        className: styles.textInputProps
      } }
      InputLabelProps={ {
        sx: {
          color: '#004AAD',
          fontWeight: "bold"
        }
      } }
    />
  )
}

export default Calc
