import { FC, useState } from "react"
import { FormTextFieldI } from "../interfaces"
import styles from "./LibImagesUI.module.scss"
import CustomFieldUI from "@saas-atoms/Field/CustomFieldUI/CustomFieldUI"
import Image from "next/image"
import ChipUI from "@saas-atoms/ChipUI/ChipUI"
import ButtonUI from "@lib-ui/atoms/ButtonUI/ButtonUI"
import { useTranslation } from "next-i18next"

const LibImagesUI: FC<FormTextFieldI> = (params) => {
  const {
    defaultValueData,
    handleChange = () => null,
    required,
    props
  } = params
  const { t } = useTranslation("translation")

  const hasValue = defaultValueData !== undefined && defaultValueData !== null && defaultValueData !== '' && (defaultValueData as Array<string>).length > 0
  const [active, setActive] = useState<boolean>(false)
  const [openLib, setOpenLib] = useState<boolean>(false)
  const [modify, setModify] = useState<boolean>(false)
  const init = () => {
    const val = hasValue ? (defaultValueData as Array<string>) : []

    const newVal: Record<string, boolean> = {};
    (props?.list ?? []).forEach((item: { url: string }) => (newVal[item.url] = val.findIndex(el => el === item.url) > -1))
    return newVal
  }

  const [images, setImages] = useState<Record<string, boolean>>(init())

  const handleClickLabel = () => {
    setActive(true)
    if (!hasValue) {
      setOpenLib(true)
    }
  }

  const getTotalSelected = () => {
    return Object.values(images).filter(val => val).length
  }

  const handleImageClick = (url: string) => {
    setModify(true)
    setImages(img => ({ ...img, [url]: !images[url] }))
  }

  const handleCloseSelection = (fromClose: boolean = true) => {
    if (fromClose) {
      setImages(init())
    }

    setModify(false)
    setOpenLib(false)
    if (!hasValue) {
      setActive(false)
    }
  }

  const handleValidSelection = () => {
    handleCloseSelection(false)
    handleChange(Object.keys(images).filter(url => images[url]))
  }

  const handleModifySelection = () => {
    setOpenLib(true)
  }

  const handleRemoveAll = () => {
    handleChange([])
    handleCloseSelection()
    const val: Record<string, boolean> = {};
    (props?.list ?? []).forEach((item: { url: string }) => (val[item.url] = false))
    setImages(val)
  }

  const maxSelection = props?.max
  const total = getTotalSelected()
  const maxItemsReached = maxSelection === total

  const buildImage = (url: string, selected: boolean) => {
    return <div key={url} className={`${styles['image-content']} ${selected ? styles['selected'] : (maxItemsReached ? styles['disabled'] : '')}`}>
      <Image
        src={url}
        className={`${styles['image']}`}
        alt={url}
        width={250}
        height={250}
        draggable={false}
        onClick={() => selected || !maxItemsReached ? handleImageClick(url) : null}
      />
    </div>
  }

  const buildHelperText = () => {
    if (maxSelection > 1) {
      if (maxSelection === total) {
        return t('field.libImagesUI.max-image')
      } else if (maxSelection !== undefined) {
        return t('field.libImagesUI.limit-image', { limit: maxSelection })
      }
    }
    return ""
  }

  return <div className={`${styles['container']} basis-full ${openLib ? 'md:basis-2/4' : 'md:basis-1/4'}`}>
    <CustomFieldUI
      {...params}
      helperText={buildHelperText()}
      hasValue={hasValue}
      className={`${styles['libImageContainer']} ${openLib ? styles['active'] : ''} content-center`}
      active={active}
      required={required}
      handleClickLabel={handleClickLabel}
      openTooltip={false}
    >
      <div className={`grid grid-cols-2 md:grid-cols-3 gap-3 py-1.5 px-3 ${styles['content']}`}>
        {Object.entries(images).map(([url, selected]) => buildImage(url, selected))}
      </div>
      <div className={`flex justify-between gap-3 m-3 ${styles['button']}`}>
        <div className={"grow"}>{total > 0 && <>{t('field.libImagesUI.label.selected', { count: total })}</>}</div>
        <ButtonUI label={t('field.libImagesUI.button.cancel')} variant="outlined" onClick={() => handleCloseSelection()} />
        <ButtonUI label={t('field.libImagesUI.button.save')} disabled={!modify} onClick={handleValidSelection} />
      </div>
      <div className={`p-1 flex ${styles['label-content']}`}>
        {hasValue && !openLib && (
          <ChipUI
            label={t('field.libImagesUI.label.chip', { count: total })}
            onDelete={handleRemoveAll}
          />
        )}
        <div className="grow" onClick={handleModifySelection}></div>
      </div>
    </CustomFieldUI>
  </div>
}

export default LibImagesUI
