import { FC, MouseEvent, ReactNode, useContext } from "react"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import IconButton from "@mui/material/IconButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import Tooltip from "@mui/material/Tooltip"
import { useTheme } from "@mui/material/styles"
import Menu from "@mui/material/Menu"
import { IconHelp, IconX } from "@tabler/icons-react"
import styles from './MenuHelp.module.scss'
import { menuList } from "./menuList.config"
import { MenuHelpPropsI } from "./interfaces"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import ContactForm from "@saas-organisms/Form/Contact/ContactForm"
import { MenuUIItemPropsI } from "@lib-ui/molecules/MenuUI/interfaces"
import MenuUI from "@lib-ui/molecules/MenuUI/MenuUI"
import { batch, setOpenContact } from "@saas-reducers/Contact"
import { contactInitState } from "@saas-reducers/Contact/interfaces"
import ReducerContext from "@saas-reducers/ReducerContext"

const MenuHelp: FC<MenuHelpPropsI> = ({
  anchorEl,
  handleClick
}: MenuHelpPropsI) => {
  const { t, i18n } = useTranslation("translation")
  const { contactState: state, contactDispatch: dispatch } = useContext(ReducerContext)

  const theme = useTheme()

  const openMenu = Boolean(anchorEl)

  const handleCloseDialog = () => {
    dispatch(setOpenContact({ open: false }))
  }

  const handleOpenDialog = (e: MouseEvent<HTMLElement>) => {
    handleClick(e)
    dispatch(batch({ open: true, reportType: contactInitState.reportType }))
  }

  const label = t('menu.help.button')
  const formatLink = (e: MenuUIItemPropsI): ReactNode | null => {
    return !e.link ? <>{e.icon} {e.label}</> :
      <Link href={e.link} locale={i18n.language} className={styles['link-container']}>
        <ListItemIcon data-testid="icon">{e.icon}</ListItemIcon> {e.label}
      </Link>
  }

  return <>
    <Tooltip title={label} enterDelay={500} leaveDelay={0}>
      <IconButton
        aria-label={label}
        className={styles['menu-button']}
        color="primary"
        sx={ { mx: 1 } }
        onClick={handleClick}
        data-testid="menu-help"
      >
        <IconHelp color={theme.palette.primary.main} stroke={1.25} />
      </IconButton>
    </Tooltip>
    <Menu
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleClick}
      className={styles['menu-content']}
      MenuListProps={ {
        'aria-labelledby': 'help-button',
      } }
    >
      <MenuUI
        list={false}
        items={menuList(t, theme, handleOpenDialog)}
        hoverItems={ {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.tertiary.main,
        } }
        customFormatLink={formatLink}
      />
    </Menu>
    <Dialog
      onClose={handleCloseDialog}
      open={state.open}
      fullWidth={true}
      maxWidth={"md"}
    >
      <IconButton
        aria-label="close"
        onClick={handleCloseDialog}
        sx={ {
          position: 'absolute',
          right: 0,
          top: 0,
          color: (theme) => theme.palette.grey[500],
        } }
      >
        <IconX stroke={1.25} />
      </IconButton>
      <DialogContent>
        <ContactForm />
      </DialogContent>
    </Dialog>
  </>
}

export default MenuHelp
