import * as yup from "yup"
import { DynamicSchemaTypePropsI } from "@saas-organisms/Form/Dynamic/interfaces"

export const initValuesContactForm = {
  encounteredProblem: ""
}

export const schemaContactForm: Array<DynamicSchemaTypePropsI> = [
  {
    name: 'encounteredProblem',
    label: 'contactPage.dynamicForm.encounteredProblem',
    required: false,
    type: 'select',
    col: 6,
  },
  {
    name: 'messageContent',
    label: 'contactPage.messageContent',
    required: true,
    type: 'textarea',
    col: 6,
  }
]

export const validationSchemaContactForm = yup.object().shape({
  messageContent: yup.string()
})
