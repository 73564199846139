import { FC } from 'react'
import Link from '@mui/material/Link'
import { LinkUIPropsI } from './interfaces'
import styles from './LinkUI.module.scss'

const LinkUI: FC<LinkUIPropsI> = ({
  icon,
  color = 'black',
  linkText = 'Link 1',
  href = '#',
  underline = 'none'
}: LinkUIPropsI) => (
  <Link className={styles['link-container']} href={href} underline={underline} style={ { color } }>
    {icon !== undefined
      ?
      <>{icon} {linkText}</>
      :
      linkText
    }
  </Link>
)

export default LinkUI
