import { FC } from "react"
import Head from "next/head"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { HeadBaseLayoutPropI } from "./interfaces"

const HeadBaseLayout: FC<HeadBaseLayoutPropI> = ({ path, title, desc }: HeadBaseLayoutPropI) => {
  const { t } = useTranslation('meta')
  const router = useRouter()

  const prefix = path ?? router.pathname
  title = String(title ?? t(`meta:${prefix}.title`))
  desc = String(desc ?? t(`meta:${prefix}.desc`) ?? "")

  let env = process.env.NEXT_PUBLIC_ENV!
  env = env === 'production' ? "" : `_${env}`

  return <Head>
    <title>{title}</title>
    {desc && <meta name="description" content={desc} />}
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <link rel="shortcut icon" type="image/vnd.microsoft.icon" href={`/favicon${env}.svg`} />
  </Head>
}

export default HeadBaseLayout
