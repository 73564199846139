import { FC } from "react"
import { FormTextFieldI, ValuePropsI } from "../interfaces"
import TextUI from "../TextUI/TextUI"

const NumberUI: FC<FormTextFieldI> = (props: FormTextFieldI) => {
  const {
    handleChange = () => null,
    ...rest
  } = props

  const handleChangeNumber = (value: ValuePropsI) => {
    const regex = /^-?\d*[,.]?\d*$/

    if (value === '') {
      handleChange('')
    } else if (regex.test(value as string)) {
      const valString = (value as string).replace(/,/, '.')
      const valNumber = Number(valString)

      handleChange(/(^0|\.)$/.test(valString) ? valString : valNumber)
    }
  }

  return <TextUI
    {...rest}
    type="text"
    handleChange={handleChangeNumber}
  />
}

export default NumberUI
